import { PerspectiveCamera, Texture, Vector2, WebGLRenderer } from "three";
import Scene from "./WorldScene";
import { StereoEffect } from "./utils/StereoEffect";

export default class Renderer extends WebGLRenderer {
  private declare isLandscape: boolean;
  private declare isStereoMode: boolean;
  public declare stereoEffect: StereoEffect;

  sizeVector = new Vector2();
  currentPixelRatio = Math.min(window.devicePixelRatio, 2);

  constructor({
    orientation,
    stereoMode,
  }: {
    orientation: "landscape" | "portrait";
    stereoMode: boolean;
  }) {
    super({ powerPreference: "high-performance", preserveDrawingBuffer: true });

    this.isLandscape = orientation === "landscape";
    this.isStereoMode = stereoMode;
    this.stereoEffect = new StereoEffect(this);

    document.body.appendChild(this.domElement);

    this.stereoEffect.setSize(window.innerWidth, window.innerHeight);
    this.setPixelRatio(this.currentPixelRatio);
    this.setSize(window.innerWidth, window.innerHeight);
    this.setupListeners();
  }

  setupListeners = () => {
    window.addEventListener("resize", () => {
      this.setSize(window.innerWidth, window.innerHeight);
      this.stereoEffect.setSize(window.innerWidth, window.innerHeight);
      this.setPixelRatio(this.currentPixelRatio);
    });

    document.addEventListener("rnib:eye-sep", (e: CustomEventInit) => {
      const eyeSep = e.detail.eyeSep;
      if (eyeSep) this.stereoEffect.setEyeSeparation(eyeSep);
    });
  };

  public setStereoMode = (val: boolean) => (this.isStereoMode = val);

  private renderLandscape = (
    scene: Scene,
    camera: PerspectiveCamera,
    textureLeft: Texture,
    textureRight: Texture
  ) => {
    this.stereoEffect.render(scene, camera, textureLeft, textureRight);
  };

  private renderPortrait = (
    scene: Scene,
    backgroundTexture: Texture,
    camera: PerspectiveCamera
  ) => {
    scene.background = backgroundTexture;
    this.render(scene, camera);
  };

  mainRender = (scene: Scene, camera: PerspectiveCamera, textureLeft: Texture, textureRight: Texture) => {
    if (this.isStereoMode && this.isLandscape) this.renderLandscape(scene, camera, textureLeft, textureRight);
    else this.renderPortrait(scene, textureLeft, camera);
  };
}
