import { switchToMeshEvent } from "../utils/Events";
import { once } from '../utils';
const inGameUI = document.querySelector(".ui");

const parentElement = inGameUI?.querySelector("#external-site");
const homepageElement = inGameUI?.querySelector("#homepage");

const backButton = parentElement?.querySelector("#external-site-back-button");
const confirmButton = parentElement?.querySelector("#external-site-confirm-button");


let currentLink: string;

export function setExternalLink(link: string) {
  currentLink = link;
}

const ExternalLink = once((orientation: string) => {
  confirmButton?.addEventListener("click", () => {
    //console.log('WINDOW OPEN ' + currentLink)
    window.open(currentLink);
  });

  backButton?.addEventListener("click", () => {
    if (orientation === "portrait") {
      parentElement?.classList.remove("active");
      homepageElement?.classList.add("active");
    } else {
      switchToMeshEvent(["homepage"]);
    }
  });
});

export default ExternalLink;
