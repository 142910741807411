export const states = [
  "DEFAULT",
  "CAMERA_PERMISSIONS",
  "NO_CONTROLLER_DETECTED",
  "CONTROLLER_DETECTED",
  "VR_WARNING",
  "PLACE_YOUR_HEADSET",
  "WEBGL",
  "WEBGL__GAMEPAD",
  "WEBGL__FREELOOK",
] as const;

export type UIStates = (typeof states)[number];

export type ScreenOrientation = "landscape" | "portrait";

export type Nullable<T> = T | null;
