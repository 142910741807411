import taskData from "../json/tasks.json";
import eyeConditionsData from "../json/eye-conditions.json";
import GamepadObject from "./utils/GamepadObject";
import {
  continueTaskEvent,
  continueTaskEvent2,
  endTaskEvent,
  resetTaskEvent,
  setEyeConditionEvent,
  updateUIEvent,
} from "../dom/utils/Events";
import { Nullable } from "../types";
import { findDomElement } from "../dom/utils";

const main = findDomElement("#main");

const toolTip = main?.querySelector("#tooltip-main-button");
const rnibInformationInner = main?.querySelector(".rnib-expanded-info span");

const taskModeYesButton = main?.querySelector("#task-mode__yes-button");
const taskModeNoButton = main?.querySelector("#task-mode__no-button");
const taskModeContinueButton = main?.querySelector("#task-mode__continue-button");

const taskModeSubtitles = main?.querySelector(".task-mode__subtitles");
const soundButton = main?.querySelector("#task-mode__sound-button")!;

// const taskModeGrid = main?.querySelector(".task-mode-grid") as HTMLElement;

type Task = {
  name: string;
  tagName: string;
  script: {
    type: string;
    copy: string;
    screenTime: number;
  }[];
};

export default class TaskMode {
  private voiceovers: Array<Array<HTMLAudioElement>> = [[], [], [], []];
  private taskData;
  private eyeConditionsData;
  // private taskModeSubtitles: HTMLDivElement;
  // public textLabel: CSS2DObject;

  private currentTask?: Task | null;
  private currentTaskNum: number;
  private scriptIndex: number = 0;
  private readScriptTimeout: number | undefined = undefined;

  private gamepadObject: Nullable<GamepadObject>;
  private isInputPressed: boolean;

  private taskActive: boolean;

  private voiceoverNum: number;

  constructor(gamepadObject: Nullable<GamepadObject>) {
    this.currentTaskNum = 0;

    this.voiceoverNum = 0;

    for (let i = 0; i < 4; i++) {
      for (let n = 0; n < 4; n++) {
        let voiceover = <HTMLAudioElement>document.querySelector("#voiceover" + i + "_" + n);
        voiceover.load();
        this.voiceovers[i].push(voiceover);
      }
    }

    // this.textLabel = new CSS2DObject(taskModeSubtitles);
    // this.scene.add(this.textLabel);
    // this.textLabel.layers.set(0);

    this.gamepadObject = gamepadObject;
    this.isInputPressed = false;

    this.taskData = taskData["task-mode"];
    this.eyeConditionsData = eyeConditionsData["eye-conditions"];
    this.taskActive = false;

    //if (!taskModeSubtitles) return;
    // textLabel.position.set(-1.6, -0.7, -3);

    document.addEventListener("rnib:task-mode", (e: CustomEventInit) => {
      const task = e.detail.task;
      const state = e.detail.state;
      if (state) {
        if (task) {
          if (!this.taskActive) {
            this.startTask(task);
          }
        } else {
        }
      } else {
        this.closeTask();
      }
    });

    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState == "visible") {
        console.log("tab is active");
        this.resumeTask();
      } else {
        console.log("tab is inactive");
        this.pauseTask();
      }
    });

    this.gamepadObject && this.gamepadObject.addEventListener("button0", this.triggerGamepadInput);

    taskModeNoButton?.addEventListener("click", () => {
      this.closeTask();
    });

    taskModeContinueButton?.addEventListener("click", () => {
      console.log("continue pressed");
      this.isInputPressed = true;
    });

    function setSoundButtonHTML(state: string) {
      soundButton.innerHTML = `
          Sound ${state.toUpperCase()}
          <div class="button-icon" data-icon="sound-${state.toLowerCase()}">
        `;
    }

    soundButton?.addEventListener("click", () => {
      if (soundButton.classList.contains("soundOff")) {
        setSoundButtonHTML("on");
        soundButton.classList.remove("soundOff");
        this.voiceovers.forEach(voiceover => {
          voiceover.forEach(vo => {
            vo.muted = false;
          });
        });
      } else {
        setSoundButtonHTML("off");
        soundButton.classList.add("soundOff");
        this.voiceovers.forEach(voiceover => {
          voiceover.forEach(vo => {
            vo.muted = true;
          });
        });
      }
    });
  }

  // private updateText(copy: string) {
  //   if (taskModeSubtitles) {
  //     console.log('updateText' + copy)
  //     taskModeSubtitles.innerHTML = copy;
  //   }
  // }

  private triggerGamepadInput = () => {
    this.isInputPressed = true;
  };

  public checkTaskInput() {
    if (this.isInputPressed && this.currentTask) {
      console.log("input pressed");
      this.readScript(this.currentTask);

      this.isInputPressed = false;
    }
  }

  private readScript(task: Task, _customText?: string) {
    this.voiceovers[this.currentTaskNum][this.voiceoverNum]?.play();
    // if (!customText) {
    //   this.updateText(task.script[this.scriptIndex].copy);
    // } else {
    //   this.updateText(customText);
    // }
    if (taskModeSubtitles != null) {
      taskModeSubtitles.innerHTML = task.script[this.scriptIndex].copy;
    }

    updateUIEvent();

    if (task.script[this.scriptIndex].type) {
      let givenEyeCondition;

      switch (task.script[this.scriptIndex].type) {
        case "text":
          this.readScriptTimeout = setTimeout(() => {
            if (this.currentTask) {
              this.readScript(this.currentTask);
            }
          }, task.script[this.scriptIndex].screenTime);

          break;
        case "input":
          this.readScriptTimeout = setTimeout(() => {
            // if (this.currentTask) {
            //   this.voiceovers[this.currentTaskNum]?.pause();
            // }
            this.voiceoverNum++;
            continueTaskEvent();

            //toolTip?.dispatchEvent(clickEvent);
          }, task.script[this.scriptIndex].screenTime);

          break;
        case "input2":
          this.readScriptTimeout = setTimeout(() => {
            // if (this.currentTask) {
            //   this.voiceovers[this.currentTaskNum][this.voiceoverNum]?.pause();
            // }
            this.voiceoverNum++;
            continueTaskEvent2();
            console.log("SHOW tooltip - input2 - (eye condition)");
            toolTip?.classList.remove("hidden");
            //toolTip?.dispatchEvent(clickEvent);
          }, task.script[this.scriptIndex].screenTime);
          break;
        case "random":
          if (toolTip) {
            toolTip.classList.remove("expanded-info");
            console.log("random tooltip unhidden");
            toolTip?.classList.remove("hidden");
          }
          // random, excluding the color blindess and 20/20 vision
          const random = Math.floor(Math.random() * (this.eyeConditionsData.length - 2)) + 1;

          setEyeConditionEvent(this.eyeConditionsData[random].name, 4);

          givenEyeCondition = eyeConditionsData["eye-conditions"].find(
            eyecondition => eyecondition.name === this.eyeConditionsData[random].name
          );

          if (givenEyeCondition && givenEyeCondition.url) {
            taskModeYesButton?.setAttribute("data-href", givenEyeCondition.url);
          }
          if (toolTip && rnibInformationInner && givenEyeCondition) {
            toolTip.innerHTML = givenEyeCondition?.tagName;
            rnibInformationInner.innerHTML = givenEyeCondition?.desc;
          }
          this.readScriptTimeout = setTimeout(() => {
            if (this.currentTask) {
              this.voiceoverNum++;
              this.readScript(this.currentTask);
            }
          }, task.script[this.scriptIndex].screenTime + 10000);
          break;
        case "end":
          this.readScriptTimeout = setTimeout(() => {
            endTaskEvent();
          }, task.script[this.scriptIndex].screenTime);

          break;
        // default:
        //   setEyeConditionEvent(task.script[this.scriptIndex].type, 4);
        //   if (this.currentTask) {
        //     this.readScript(this.currentTask);
        //   }

        //   givenEyeCondition = eyeConditionsData["eye-conditions"].find(
        //     eyecondition => eyecondition.name === task.script[this.scriptIndex].type
        //   );
        //   if (givenEyeCondition && givenEyeCondition.url) {
        //     taskModeYesButton?.setAttribute("data-href", givenEyeCondition.url);
        //   }
        //   setTimeout(() => {
        //     setEyeConditionEvent("none", 1);
        //   }, task.script[this.scriptIndex].screenTime)
      }
    } else {
      if (this.scriptIndex === task.script[this.scriptIndex].type.length) {
        endTaskEvent();
      }
    }
    this.scriptIndex++;
  }

  private closeTask() {
    console.log("close task");
    this.voiceovers.forEach(voiceover => {
      voiceover.forEach(vo => {
        vo.pause();
        vo.currentTime = 0;
      });
    });

    setEyeConditionEvent("none", 1);
    this.scriptIndex = 0;
    this.currentTask = null;
    this.voiceoverNum = 0;
    taskModeSubtitles?.classList.remove("active");
    clearTimeout(this.readScriptTimeout);
    this.taskActive = false;
  }

  private pauseTask() {
    console.log("pause task");
    this.voiceovers.forEach(voiceover => {
      voiceover.forEach(vo => {
        vo.pause();
        vo.currentTime = 0;
      });
    });

    setEyeConditionEvent("none", 1);
    this.scriptIndex = 0;
    clearTimeout(this.readScriptTimeout);
    this.taskActive = false;
    this.voiceoverNum = 0;
  }

  private resumeTask() {
    // console.log('resume task')
    if (this.currentTask != null) {
      //   console.log('resume task')
      //   this.readScript(this.currentTask)

      this.startTask(this.currentTask.name);
      resetTaskEvent();
    }
  }

  public startTask(taskName: string) {
    this.voiceoverNum = 0;
    this.taskActive = true;
    taskModeSubtitles?.classList.add("active");
    const tasks = this.taskData.scenes;
    toolTip?.classList.add("unclickable");
    toolTip?.classList.add("taskMode");
    //const foundTask = tasks.find(task => task.tagName === taskName);
    tasks.forEach((task, i) => {
      if (task.tagName == taskName) {
        this.currentTask = task;
        this.currentTaskNum = i;
      }
    });

    if (this.currentTask != null) {
      this.scriptIndex = 0;
      this.readScript(this.currentTask);
    }
  }
}
