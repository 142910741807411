import Reticle from "./Reticle";
import { Group, Raycaster, Vector3 } from "three";

import type { Clock, Object3D, Intersection } from "three";
import type MeshUI from "./MeshUI";

export default class Pointer extends Group {
  private reticle: Reticle;

  private readonly raycaster = new Raycaster();
  private readonly originVector = new Vector3();
  private readonly directionVector = new Vector3();

  constructor(clock: Clock) {
    super();
    this.reticle = new Reticle(clock);
    this.reticle.position.z = -5.95;

    this.add(this.reticle);
  }

  objectInterceptor = (objects: Object3D[]): Intersection<MeshUI>[] => {
    // calculate objects intersecting the picking ray
    // // update the picking ray with the camera and pointer position
    this.getWorldDirection(this.directionVector);
    this.raycaster.set(this.originVector, this.directionVector.negate());

    return this.raycaster.intersectObjects(objects);
  };

  update = (objects: Object3D[]) => {
    // this.reticle?.arcMesh.lookAt(this.perspectiveCamera.position);
    // this.reticle?.arcGhostMesh.lookAt(this.perspectiveCamera.position);
    this.reticle?.rotateSprite();
    return this.objectInterceptor(objects);
  };
}
