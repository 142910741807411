import { Nullable } from "../../types";

export const findDomElement = <K extends keyof HTMLElementTagNameMap>(
  elemName: string,
  elemToQuery?: HTMLElement
): HTMLElementTagNameMap[K] => {
  elemToQuery || (elemToQuery = document as unknown as HTMLElement);

  let result: Nullable<HTMLElementTagNameMap[K]>;

  if ((result = elemToQuery.querySelector<HTMLElementTagNameMap[K]>(elemName)) === null)
    throw Error("Queried DOM element that doesn't exist: " + elemName);

  return result;
};

export const getScreenOrientation = () => {
  if (window.innerHeight > window.innerWidth) return "portrait";
  else return "landscape";
};

// run a function once only and cache the result
// this is needed because Main/Homepage/Tutorial/ExternalLink setup functions are being called
// multiple times from different places, resulting in duplicate DOM nodes and event handlers
export const once = (func: Function) => {
  let result: any;
  let done = false;
  return (...args: any[]) => {
    if (done) return result;
    else {
      result = func(...args);
      done = true;
      return result;
    }
  };
};
