import { Vector3 } from "three";
import { changeFocusLength } from "../../params";

const isIOS = () => {
  return (
    /iPad|iPhone|iPod/.test(navigator.userAgent) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  );
};

export const extractRgb = (css: string) => {
  return css.match(/[0-9.]+/gi);
};

const isAndroid = () => {
  return /android/i.test(navigator.userAgent);
};

export const getMobileOS = () => {
  if (isAndroid()) {
    return "Android";
  } else if (isIOS()) {
    return "iOS";
  }
  return "Other";
};

export const raise = (errorMessage: string): never => {
  throw new Error(errorMessage);
};

export const isWithinRange = (val: number, min: number, max: number) => min <= val && max >= val;

export const yAxis = new Vector3(0, 1, 0);

export const moveToNexTabbableElement = () => {};

window.addEventListener("VoiceoverInfo", (e: any) => {
  console.log("VoiceoverInfo, e: ", e);
  console.log("VoiceoverInfo, e.detail.info: ", e.detail.info);

  let voiceoverActive = e.detail.info == "true" ? true : false;
  changeFocusLength(voiceoverActive);
});

callback();
function callback() {
  try {
    (window as any).webkit.messageHandlers.callbackRnib.postMessage("{}");
    console.log("vo callback successful");
  } catch {
    changeFocusLength(false);
    console.log("vo failed callback");
  }
}
