export const focusOnEvent = new Event("rnib:focuson", {
  bubbles: true,
  cancelable: true,
});
export const focusOutEvent = new Event("rnib:focusout", {
  bubbles: true,
  cancelable: true,
});
export const clickEvent = new Event("rnib:click", {
  bubbles: true,
  cancelable: true,
});
export const refocusEvent = new Event("rnib:refocus", {
  bubbles: true,
  cancelable: true,
});

export const endTaskEvent = () => {
  const event = new Event("rnib:end-task", {
    bubbles: true,
    cancelable: true,
  });
  document?.dispatchEvent(event);
};

export const resetTaskEvent = () => {
  const event = new Event("rnib:reset-task", {
    bubbles: true,
    cancelable: true,
  });
  document?.dispatchEvent(event);
};

export const continueTaskEvent = () => {
  const event = new Event("rnib:continue-task", {
    bubbles: true,
    cancelable: true,
  });
  document?.dispatchEvent(event);
};

export const continueTaskEvent2 = () => {
  const event = new Event("rnib:continue-task2", {
    bubbles: true,
    cancelable: true,
  });
  document?.dispatchEvent(event);
};

export const switchToMeshEvent = (meshes: Array<string>) => {
  const event = new CustomEvent("rnib:switch", {
    detail: {
      meshes: meshes,
    },
  });
  document?.dispatchEvent(event);
};

export const switchToVideoEvent = (state: boolean) => {
  const event = new CustomEvent("rnib:360-video", {
    detail: {
      state: state,
    },
  });
  document?.dispatchEvent(event);
};

export const setEyeConditionEvent = (eyeCondition: string, severityLevel: number) => {
  const event = new CustomEvent("rnib:condition", {
    detail: {
      condition: eyeCondition,
      severity: severityLevel,
    },
  });
  document?.dispatchEvent(event);
};

export const setTaskEvent = (task: string, state: boolean) => {
  const event = new CustomEvent("rnib:task-mode", {
    detail: {
      task: task,
      state: state,
    },
  });
  document?.dispatchEvent(event);
};

export const closeTaskEvent = () => {
  const event = new CustomEvent("rnib:task-mode", {
    detail: {
      state: false,
    },
  });
  document?.dispatchEvent(event);
};

export const eyeSepEvent = (eyeSep: number) => {
  const event = new CustomEvent("rnib:eye-sep", {
    detail: {
      eyeSep: eyeSep,
    },
  });
  document?.dispatchEvent(event);
};


export const updateUIEvent = () => {
  console.log('bug test updateUIEvent')
  const event = new Event("rnib:update-ui", {
    bubbles: true,
    cancelable: true,
  });
  document?.dispatchEvent(event);
};