import { Nullable } from "../../types";

import { getMobileOS } from "./index";

type ActiveButton = GamepadButton & { index: number };
type ActiveStates = [ActiveButton[], readonly number[]];

class JoyCon {
  side: "right" | "left";
  platform: ReturnType<typeof getMobileOS>;
  private patchedEvent: Nullable<"select"> = null;

  constructor(controllerId: string, platform: ReturnType<typeof getMobileOS>) {
    this.side = this.determineSide(controllerId);
    this.platform = platform;

    this.side === "left" && this.isAndroid() && this.patchLeftJoyconAndroid();
  }

  private determineSide = (controllerId: string): (typeof this)["side"] => {
    switch (true) {
      case controllerId.includes("Left") || controllerId.includes("(L)"):
        return "left";
      case controllerId.includes("Right") || controllerId.includes("(R)"):
        return "right";
      default:
        throw Error("Not a valid Joycon");
    }
  };

  private isIOS = () => this.platform === "iOS";
  private isAndroid = () => this.platform === "Android";

  private patchLeftJoyconAndroid = () => {
    window.addEventListener("keydown", evt => {
      if (evt.code === "" && evt.key === "Unidentified") this.patchedEvent = "select";
    });
  };

  private parseIOSButtonsInputs = (buttonIndex: number) => {
    const options = {
      left: ["right", "left", "up", "down"],
      right: ["left", "right", "down", "up"],
    };
    return options[this.side][buttonIndex - 12] ?? "";
  };

  parseControllerInputs = (controllerStates: ActiveStates) => {
    const [buttons, axes] = controllerStates;
    let direction = "";

    if (axes[0] > 0.8 || axes[2] > 0.8) direction = "right";
    else if (axes[0] < -0.8 || axes[2] < -0.8) direction = "left";
    else if (axes[1] > 0.8 || axes[3] > 0.8) direction = "down";
    else if (axes[1] < -0.8 || axes[3] < -0.8) direction = "up";

    if (direction.length) return direction;

    buttons.length &&
      buttons.forEach(button => {
        if (button.index <= 7) direction = "select";
        if (!direction.length && this.isIOS()) direction = this.parseIOSButtonsInputs(button.index);
      });

    if (this.isAndroid() && this.patchedEvent) {
      direction = this.patchedEvent;
      this.patchedEvent = null;
    }

    return direction;
  };
}

export default class GamepadObject extends EventTarget {
  private readonly controllers: Array<Gamepad> = [];
  private declare connectedJoycon: JoyCon;
  private mobileOs: ReturnType<typeof getMobileOS> = getMobileOS();
  private connectedController: Nullable<Gamepad> = null;
  private currentActiveStates: Nullable<ActiveStates> = null;
  private scanInterval: Nullable<number> = null;

  public isConnected = false;
  private lastDispatchedEvent: { eventName: Nullable<string>; timeoutRef: Nullable<number> } = {
    eventName: null,
    timeoutRef: null,
  };

  private addGamepad = (gamepad: Gamepad) => {
    this.controllers[gamepad.index] = gamepad;
    this.connectedController = gamepad;
    this.isConnected = true;

    this.scanInterval && clearInterval(this.scanInterval);
    this.scanInterval = null;

    this.connectedJoycon = new JoyCon(this.connectedController.id, this.mobileOs);
  };

  private getCurrentGamepad = () => navigator.getGamepads()[0];

  private disconnectHandler = (event: GamepadEvent) => {
    const gamepad = event.gamepad;
    this.removeGamepad(gamepad);
    this.startDeviceScan();
  };

  private connectHandler = (event: GamepadEvent) => {
    const gamepad = event.gamepad;
    this.addGamepad(gamepad);
    requestAnimationFrame(this.updateStatus);
  };

  private removeGamepad = (gamepad: { index: number }) => {
    delete this.controllers[gamepad.index];
    this.isConnected = false;
  };

  // Need now to refactor how we detect buttons and axes
  // Currently no way to easily detect which button has been pressed

  private getActiveStates = (gamepad: Gamepad): ActiveStates => {
    return [
      gamepad.buttons.reduce<ActiveButton[]>((prev, curr, i) => {
        if (curr.pressed) {
          (curr as ActiveButton).index = i;
          prev.push(curr as ActiveButton);
        }

        return prev;
      }, []),
      gamepad.axes,
    ];
  };

  private dispatchThrottledEvent = (eventName: string) => {
    if (this.lastDispatchedEvent.eventName === eventName) return;
    if (this.lastDispatchedEvent.timeoutRef) clearTimeout(this.lastDispatchedEvent.timeoutRef);

    this.lastDispatchedEvent.eventName = eventName;
    this.lastDispatchedEvent.timeoutRef = setTimeout(() => {
      this.lastDispatchedEvent.eventName = this.lastDispatchedEvent.timeoutRef = null;
    }, 1000);

    eventName === "select"
      ? this.dispatchEvent(new CustomEvent(eventName, { bubbles: true }))
      : this.dispatchEvent(new CustomEvent("direction", { bubbles: true, detail: eventName }));
  };

  private dispatchEvents = () => {
    if (!this.currentActiveStates) return;

    const input = this.connectedJoycon.parseControllerInputs(this.currentActiveStates);

    input.length && this.dispatchThrottledEvent(input);
  };

  private updateStatus = () => {
    this.connectedController = this.getCurrentGamepad();
    if (!this.connectedController) return;

    this.currentActiveStates = this.getActiveStates(this.connectedController);

    this.dispatchEvents();

    requestAnimationFrame(this.updateStatus);
  };

  // controllers will only be visible after gamepadconnected is triggered
  private scanGamepads = () => {
    navigator
      .getGamepads() //
      .forEach(gamepad => {
        if (gamepad) this.addGamepad(gamepad);
      });
  };

  private startDeviceScan = () => {
    this.scanInterval = setInterval(this.scanGamepads, 500);
  };

  public init = (connectCallback?: () => void, disconnectCallback?: () => void) => {
    window.addEventListener("IOScontrollerInput", console.log);

    window.addEventListener("gamepadconnected", e => {
      this.connectHandler(e);
      connectCallback && connectCallback();
    });
    window.addEventListener("gamepaddisconnected", e => {
      this.disconnectHandler(e);
      disconnectCallback && disconnectCallback();
    });
  };
}
