import * as THREE from "three";
import MeshUI from "./components/MeshUI";
import { ReCentreAR } from "./components/ReCentreAR";
import { PanoramicVideo } from "./PanoramicVideo";
import Pointer from "./components/Pointer";

import type { Nullable } from "../types";
import TaskMode from "./TaskMode";
import GamepadObject from "./utils/GamepadObject";

import { yAxis } from "./utils";
import { findDomElement } from "../dom/utils";
import { switchToMeshEvent } from "../dom/utils/Events";
import { degToRad } from "three/src/math/MathUtils";

export default class World extends THREE.Scene {
  private perspectiveCamera: THREE.PerspectiveCamera;
  private clock: THREE.Clock;
  private gamepadObject: GamepadObject | null;
  private controllerDetected?: Boolean;

  private taskMode?: TaskMode;

  private tutorial?: MeshUI;
  private homepage?: MeshUI;
  private main?: MeshUI;
  private severityScale?: MeshUI;
  private externalSite?: MeshUI;
  public declare panoramicVideo: PanoramicVideo;

  private reCentreAR?: ReCentreAR;
  private pivot: THREE.Group;

  private meshUIArr: Array<MeshUI> = [];
  private pointer?: Pointer;
  private orientation: string;

  private currentIntersected: Nullable<THREE.Intersection<MeshUI>> = null;

  constructor(
    perspectiveCamera: THREE.PerspectiveCamera,
    orientation: string,
    gamepadObject: GamepadObject | null
  ) {
    super();
    this.gamepadObject = gamepadObject;
    this.perspectiveCamera = perspectiveCamera;
    this.clock = new THREE.Clock();
    this.orientation = orientation;
    this.pivot = new THREE.Group();

    this.add(this.perspectiveCamera);

    this.gamepadObject?.addEventListener("direction", ev => {
      const mappings: { [key: string]: number } = {
        up: 0,
        right: 1,
        down: 2,
        left: 3,
      };
      this.triggerTabActionInCurrentScreen(mappings[(ev as any).detail!]);
    });

    this.gamepadObject?.addEventListener("select", this.triggerTabActionUserSelect);
  }

  private addPointer = () => {
    this.pointer = new Pointer(this.clock);
    this.perspectiveCamera.add(this.pointer);
  };

  private triggerTabActionInCurrentScreen = (direction: number) => {
    const currScreen = this.meshUIArr.find(
      child => child.visible && !(child instanceof ReCentreAR)
    );
    currScreen?.triggerSelectElementFromDirection(direction);
  };

  private triggerTabActionUserSelect = () => {
    const currScreen = this.meshUIArr.find(
      child => child.visible && !(child instanceof ReCentreAR)
    );

    currScreen?.triggerClickSelect();
  };

  private navigateToMesh = (meshName: string) => {
    switchToMeshEvent([meshName]);
  };

  private checkIfIntersectingActiveUI = (intersections: THREE.Intersection<MeshUI>[]) => {
    if (intersections.length > 0) {
      this.currentIntersected = intersections.find(i => i.object.visible) ?? null;
      this.currentIntersected?.object?.checkUICoordinates(this.currentIntersected.uv!);
    } else if (this.currentIntersected) {
      this.currentIntersected = null;
    }
  };

  public showInitialMesh(controllerDetected: boolean) {
    this.controllerDetected = controllerDetected;
    this.clock.start();
    // Only show controller for Gaze Control, else skip to main UI
    if (this.controllerDetected) {
      this.navigateToMesh("homepage");
      findDomElement<"button">(".select-eye-condition").focus();
    } else {
      if (this.orientation === "landscape") this.addPointer();
      this.navigateToMesh("tutorial");
      // findDomElement<"span">(".tutorial__look-down span").focus();
    }
  }

  public showInitialElement() {
    // Homepage HTML Element
    findDomElement("#homepage").classList.add("active");
  }

  public async init(headsetMode: boolean, isControllerEnabled: boolean) {
    if (!headsetMode) return;

    // Tutorial = CanvasTexture
    this.tutorial = new MeshUI("tutorial", 5, 20, new THREE.Vector3(0, 0, -6), isControllerEnabled);

    this.tutorial.init();
    // this.tutorial.planeCurve(1);
    this.meshUIArr.push(this.tutorial);
    this.pivot.add(this.tutorial);

    // Re-Centre AR - CanvasTexture
    this.reCentreAR = new ReCentreAR(isControllerEnabled);
    this.add(this.reCentreAR.pivot);
    this.meshUIArr.push(this.reCentreAR);

    // Main UI - CanvasTexture
    this.homepage = new MeshUI(
      "homepage",
      20,
      20,
      new THREE.Vector3(0, 0, -6),
      isControllerEnabled
    );
    this.homepage.init();
    // this.homepage.planeCurve(2);
    this.meshUIArr.push(this.homepage);

    // Main UI - CanvasTexture
    this.main = new MeshUI("main", 20, 20, new THREE.Vector3(0, -0.5, -6), isControllerEnabled);
    this.main.init();
    // this.main.planeCurve(2);
    this.meshUIArr.push(this.main);

    //Severity Scale - CanvasTexture
    this.severityScale = new MeshUI(
      "severity-scale-container",
      20,
      20,
      new THREE.Vector3(0, -3.856, -4.596),
      isControllerEnabled
    );
    this.severityScale.init();
    // this.severityScale.planeCurve(0.2);
    this.severityScale.rotateX(degToRad(-40));
    this.meshUIArr.push(this.severityScale);

    // External Site - CanvasTexture
    this.externalSite = new MeshUI(
      "external-site",
      20,
      20,
      new THREE.Vector3(0, 0, -6),
      isControllerEnabled
    );
    this.externalSite.init();
    // this.externalSite.planeCurve(1);
    this.meshUIArr.push(this.externalSite);

    // 360 Video
    this.panoramicVideo = new PanoramicVideo(
      "https://rnib-hls-video.s3.eu-west-1.amazonaws.com/manifest.m3u8",
      this.perspectiveCamera
    );

    // Task Mode
    this.taskMode = new TaskMode(this.gamepadObject);

    // scene is a THREE.Scene (see three.js)
    //this.add( this.tutorial.mesh);
    this.pivot.add(this.homepage);
    this.pivot.add(this.main);
    this.pivot.add(this.severityScale);
    this.pivot.add(this.externalSite);
    // this.pivot.add(this.taskMode.textLabel);

    this.add(this.pivot);
  }

  public rotatePivotToCamera = () => {
    this.pivot.setRotationFromAxisAngle(yAxis, this.perspectiveCamera.rotation.y);
  };

  public update() {
    if (this.main && this.panoramicVideo.playing) {
      this.panoramicVideo.update();
      // move the main UI down a bit to avoid obscuring the talent
      this.main.position.y = -2;
    } else if (this.main) {
      this.main.position.y = 0;
    }

    this.taskMode?.checkTaskInput();

    if (this.pointer) this.checkIfIntersectingActiveUI(this.pointer?.update(this.meshUIArr));

    this.reCentreAR?.update(this.perspectiveCamera);

    this.meshUIArr.forEach(MeshUIObj => MeshUIObj.name !== "gui" && MeshUIObj.update());
  }
}
