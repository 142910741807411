import { once } from "../utils";
import { eyeSepEvent, switchToMeshEvent } from "../utils/Events";

const inGameUI = document.querySelector<HTMLElement>(".ui")!;

const tutorial = inGameUI?.querySelector<HTMLElement>("#tutorial")!;
const gazeControlTooltip = tutorial?.querySelector<HTMLElement>("#gaze-control-tooltip")!;
const lookHereButton = tutorial?.querySelector<HTMLElement>("#look-here-button")!;
const eyeSeperationContainer = tutorial?.querySelector<HTMLElement>(".eye-seperation-container")!;
const eyeSeperationValue = tutorial?.querySelector<HTMLElement>("#eye-seperation-value")!;
const eyeSeperationProgress = tutorial?.querySelector<HTMLElement>("#eye-seperation-progress")!;
const minusButton = tutorial?.querySelector<HTMLElement>("#eye-seperation-minus-button")!;
const plusButton = tutorial?.querySelector<HTMLElement>("#eye-seperation-plus-button")!;
const continueButton = tutorial?.querySelector<HTMLButtonElement>(
  "#eye-seperation-continue-button"
)!;

const Tutorial = once(() => {
  let eyeSeperation = 3.5;
  const eyeSeperationTotal = 7;

  function setEyeSeperationValue() {
    if (!eyeSeperationValue || !eyeSeperationProgress) throw Error("Missing elements");
    const percentage = `${(eyeSeperation / eyeSeperationTotal) * 100}%`;
    eyeSeperationProgress.style.width = percentage;
    eyeSeperationValue.style.left = percentage;
    eyeSeperationValue.innerHTML = eyeSeperation.toFixed(1);
    eyeSepEvent(eyeSeperation);
  }

  setEyeSeperationValue();

  // some helper functions to manage elements styled by "hidden"'class
  function setHidden(elements: HTMLElement[], hidden: boolean) {
    elements.forEach(element => element.classList.toggle("hidden", hidden));
  }

  const show = (...args: HTMLElement[]) => setHidden(args, false);
  const hide = (...args: HTMLElement[]) => setHidden(args, true);

  function advanceToEyeSeparation() {
    hide(lookHereButton, gazeControlTooltip);
    show(eyeSeperationContainer);
  }

  function advanceToHomepage() {
    switchToMeshEvent(["homepage"]);
  }

  const clamp = (x: number, a: number, b: number) => (x < a ? a : x > b ? b : x);

  // XXX seperation is wrong spelling, but continuing here for consistency
  function adjustEyeSeperation(step: number) {
    const delta = 0.5 * step;
    eyeSeperation = clamp(eyeSeperation + delta, 0.5, 6);
    setEyeSeperationValue();
  }

  plusButton.addEventListener("click", () => adjustEyeSeperation(+1));
  minusButton.addEventListener("click", () => adjustEyeSeperation(-1));
  lookHereButton.addEventListener("click", () => advanceToEyeSeparation());
  continueButton.addEventListener("click", () => advanceToHomepage());
});

export default Tutorial;
