import { Object3D, Vector3 } from "three";
import { refocusEvent } from "../../dom/utils/Events";
import MeshUI from "./MeshUI";
import { degToRad } from "three/src/math/MathUtils";
import { yAxis } from "../utils";
const htmlElement = document.querySelector("#gui");
const reCentreARButton = htmlElement?.querySelector(".cta");
const toolTip = htmlElement?.querySelector(".tooltip");

export class ReCentreAR extends MeshUI {
  public pivot = new Object3D();

  constructor(isControllerEnabled: boolean) {
    super("gui", 20, 20, new Vector3(0, -6.128, -5.142), isControllerEnabled);
    this.rotateX(degToRad(-50));

    this.init();
    this.pivot.add(this);
    this.updateTextureFromCanvas().then(() => {
      console.log("recentre updated");
      this.visible = true;
    });

    this.refocusListen();
  }

  public showToolTip(state: boolean) {
    if (state && !toolTip?.classList.contains("hidden")) {
      this.updateTextureFromCanvas().then(() => {
        console.log("recentre updated");
        this.visible = true;
      });
      toolTip?.classList.add("hidden");
    } else if (!state && toolTip?.classList.contains("hidden")) {
      this.updateTextureFromCanvas();
      toolTip?.classList.remove("hidden");
    }
  }

  public refocusListen(): void {
    reCentreARButton?.addEventListener("click", () => {
      htmlElement?.dispatchEvent(refocusEvent);
    });
  }

  public update = (anchorObject?: Object3D) => {
    if (!anchorObject) throw Error("Missing anchor oject to get rotation from");
    this.pivot.setRotationFromAxisAngle(yAxis, anchorObject.rotation.y);
  };
}
