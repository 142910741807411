import { StereoCamera, Vector2 } from "three";
import type { PerspectiveCamera, Scene, WebGLRenderer } from "three";

class StereoEffect {
  public stereo: StereoCamera;
  private size: Vector2;
  private renderer: WebGLRenderer;
  cameraFocus = 6;

  constructor(renderer: WebGLRenderer) {
    this.stereo = new StereoCamera();
    this.renderer = renderer;
    this.stereo.aspect = 0.5;
    this.stereo.cameraL.focus = this.cameraFocus;
    this.stereo.cameraR.focus = this.cameraFocus;

    this.stereo.eyeSep = 3;
    this.size = new Vector2();

    console.warn("Auto update in render needs check if types are correct");
  }

  setEyeSeparation = (eyeSep: number) => {
    this.stereo.eyeSep = eyeSep;
  };

  setSize = (width: number, height: number) => {
    this.renderer.setSize(width, height);
  };

  render = (scene: any, camera: any, textureLeft: Texture, textureRight: Texture) => {
    if (scene.matrixWorldAutoUpdate === true) scene.updateMatrixWorld();

    if (camera.parent === null && camera.matrixWorldAutoUpdate === true) camera.updateMatrixWorld();
    camera.updateWorldMatrix(false, true);

    this.stereo.update(camera);

    this.renderer.getSize(this.size);

    if (this.renderer.autoClear) this.renderer.clear();

    this.renderer.setScissorTest(true);

    this.renderer.setScissor(0, 0, this.size.width / 2, this.size.height);
    this.renderer.setViewport(0, 0, this.size.width / 2, this.size.height);
    scene.background = textureLeft;
    this.renderer.render(scene, this.stereo.cameraL);

    this.renderer.setScissor(this.size.width / 2, 0, this.size.width / 2, this.size.height);
    this.renderer.setViewport(this.size.width / 2, 0, this.size.width / 2, this.size.height);
    scene.background = textureRight;
    this.renderer.render(scene, this.stereo.cameraR);

    this.renderer.setScissorTest(false);
  };
}

export { StereoEffect };
