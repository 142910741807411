import "./styles.css";
import { UIStates, states } from "./types/index";
import Experience from "./experience/Experience";
import GamepadObject from "./experience/utils/GamepadObject";
import { getScreenOrientation } from "./dom/utils";
import {
  revealControllerDetectedScreen,
  revealExperienceView,
  revealIntroScreen,
  revealPlaceYourHeadset,
  revealVrWarning,
  revealVrWarningScreen,
  setupIntroductionPage,
} from "./dom";

let isStereoMode = true;
let controllerEnabled = false;

let currentState: UIStates = "DEFAULT";

function navigateToPage(nextState: UIStates, experience: Experience) {
  currentState = nextState;
  switch (currentState) {
    case "CAMERA_PERMISSIONS":
      revealIntroScreen();
      break;
    case "NO_CONTROLLER_DETECTED":
      revealVrWarningScreen();
      break;
    case "CONTROLLER_DETECTED":
      revealControllerDetectedScreen();
      break;
    case "VR_WARNING":
      currentState = states[4];
      revealVrWarning();
      break;
    case "PLACE_YOUR_HEADSET":
      revealPlaceYourHeadset();
      break;
    case "WEBGL":
      revealExperienceView();
      experience.startScene(getScreenOrientation(), isStereoMode, controllerEnabled);
      break;
  }
}

const continueButtonCallback = (experience: Experience, gamepadObject: GamepadObject) => () => {
  switch (currentState) {
    case "DEFAULT":
      navigateToPage(states[1], experience);
      break;
    case "CAMERA_PERMISSIONS":
      experience.getPermissions();
      if (gamepadObject.isConnected) navigateToPage(states[3], experience);
      else navigateToPage(states[2], experience);

      break;
    case "NO_CONTROLLER_DETECTED":
      if (getScreenOrientation() === "landscape") navigateToPage(states[4], experience);
      else navigateToPage(states[6], experience);
      break;
    case "VR_WARNING":
      navigateToPage(states[5], experience);
      break;
    case "PLACE_YOUR_HEADSET":
      navigateToPage(states[6], experience);
      break;
  }
};

const skipHeadsetButtonCallback = (experience: Experience) => () => {
  isStereoMode = false;
  if (currentState === "PLACE_YOUR_HEADSET") navigateToPage(states[6], experience);
};

const yesNoCallback = (experience: Experience) => (ev: MouseEvent) => {
  controllerEnabled = (ev.currentTarget as HTMLButtonElement)?.id.includes("yes");
  navigateToPage(states[4], experience);
};

const onGamepadConnect = (experience: Experience) => {
  return () => {
    if (currentState === "NO_CONTROLLER_DETECTED" || currentState === "VR_WARNING")
      navigateToPage(states[3], experience);
  };
};

function init() {
  const gamepadObject = new GamepadObject();
  const experience = new Experience(gamepadObject, getScreenOrientation());

  gamepadObject.init(onGamepadConnect(experience));

  setupIntroductionPage()
    .addContinueButtonCallback(continueButtonCallback(experience, gamepadObject))
    .addYesButtonCallback(yesNoCallback(experience))
    .addNoButtonCallback(yesNoCallback(experience))
    .addSkipHeadsetButtonCallback(skipHeadsetButtonCallback(experience));
}

init();
