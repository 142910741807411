import { toSvg } from "html-to-image";

const showDebug = false;
const debugInfo = showDebug ? new WeakMap() : null;
const getDebugInfo = (key, create) => {
  let info = debugInfo.get(key);
  if (!info) {
    info = create(key);
    debugInfo.set(key, info);
  }
  return info;
};

export async function htmlToCanvas(node, ctx) {
  const t0 = performance.now();
  const svg = await toSvg(node, {});
  const img = await createImage(svg);
  const { width, height } = ctx.canvas;
  ctx.clearRect(0, 0, width, height);
  ctx.drawImage(img, 0, 0, width, height);
  const elapsed = performance.now() - t0;

  if (showDebug) {
    // draw outline, update count & render time for debug
    const info = getDebugInfo(node, () => ({ count: 0 }));
    info.count += 1;

    const name = node.id;
    const text = `${name}  ${info.count}  (${Math.round(elapsed)} ms)`;

    ctx.save();

    {
      ctx.font = "20px sans-serif";
      const { width: tw } = ctx.measureText(text);
      ctx.fillStyle = "rgba(0 0 0 / .5)";
      ctx.fillRect(0, 0, tw + 8, 26);
      ctx.fillStyle = "orange";
      ctx.fillText(text, 5, 20);
    }

    {
      ctx.lineWidth = 3;
      ctx.strokeStyle = "orange";
      ctx.strokeRect(0, 0, width, height);
    }

    ctx.restore();
  }
}

function createImage(url) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    Object.assign(image, {
      decode: () => resolve(image),
      onload: () => resolve(image),
      onerror: reject,
      crossOrigin: "anonymous",
      decoding: "async",
      src: url,
    });
  });
}
