import * as THREE from "three";
import { clickEvent, focusOnEvent, focusOutEvent } from "../../dom/utils/Events";
import { params } from "../../params";
import { isWithinRange } from "../utils";
import { findDomElement } from "../../dom/utils";
//@ts-expect-error
import { htmlToCanvas } from "../../dom/utils/htmlToCanvas";
import { Nullable } from "../../types";

type coordinates = {
  index: number;
  x1: number;
  x2: number;
  y1: number;
  y2: number;
};

export default class MeshUI extends THREE.Mesh<THREE.PlaneGeometry, THREE.MeshBasicMaterial> {
  private curveAxisY = false;

  private HTMLElement: HTMLElement;
  public buttonCoordinates: Array<coordinates> = [];
  private declare buttonsElements: HTMLButtonElement[];
  private currentButtonId: Nullable<number> = null;
  private backgroundUpdated: boolean = false;
  private buttonSelected: boolean = false;

  private updateCanvasTimeout: number | undefined;
  private loadingTimeout: number | undefined;

  private lerpVector?: THREE.Vector3;
  public originalPosition: THREE.Vector3;
  private lerpOutVector = new THREE.Vector3();
  private isControllerEnabled: boolean;

  /**
   Find next element with controller input direction
  
   find all elements that are on the selected direction from the current element
   from those, find which one is closest
   the closest becomes the new selectable element
   if there's no closest element in the selected direction, wrap by selecting the furthest element from the opposite direction
   */

  private declare canvasContext: CanvasRenderingContext2D;

  constructor(
    id: string,
    xSegments: number,
    ySegments: number,
    position: THREE.Vector3,
    // _curveAxisY?: boolean | undefined,
    isControllerEnabled = false
  ) {
    super();

    this.HTMLElement = findDomElement(`#${id}`);
    this.HTMLElement.classList.add("active");
    this.material = new THREE.MeshBasicMaterial({
      transparent: true,
      blending: THREE.NormalBlending,
    });
    this.geometry = new THREE.PlaneGeometry(1, 1, xSegments, ySegments);
    this.originalPosition = position;
    this.position.copy(position);
    this.name = this.HTMLElement.id;

    this.isControllerEnabled = isControllerEnabled;
    // MeshUI is invisible by default
    this.visible = false;
  }

  public planeCurve(customBendDepth: number) {
    if (!this.curveAxisY) {
      // this.bendDepth = customBendDepth;

      let z = params.bendDepth;

      if (customBendDepth) {
        z = customBendDepth;
      }

      let p = this.geometry.parameters;
      let hw = p.width * 0.5;

      let a = new THREE.Vector2(-hw, 0);
      let b = new THREE.Vector2(0, z);
      let c = new THREE.Vector2(hw, 0);

      let ab = new THREE.Vector2().subVectors(a, b);
      let bc = new THREE.Vector2().subVectors(b, c);
      let ac = new THREE.Vector2().subVectors(a, c);

      let r = (ab.length() * bc.length() * ac.length()) / (2 * Math.abs(ab.cross(ac)));

      let center = new THREE.Vector2(0, z - r);
      let baseV = new THREE.Vector2().subVectors(a, center);
      let baseAngle = baseV.angle() - Math.PI * 0.5;
      let arc = baseAngle * 2;
      let uv = this.geometry.attributes.uv as THREE.Float32BufferAttribute;
      let pos = this.geometry.attributes.position as THREE.Float32BufferAttribute;
      let mainV = new THREE.Vector2();
      for (let i = 0; i < uv.count; i++) {
        let uvRatio = 1 - uv.getX(i);
        let y = pos.getY(i);
        mainV.copy(c).rotateAround(center, arc * uvRatio);
        pos.setXYZ(i, mainV.x, y, -mainV.y);
      }

      pos.needsUpdate = true;
    } else {
      let z = params.bendDepth;

      if (customBendDepth) {
        z = customBendDepth;
      }

      let p = this.geometry.parameters;
      let vh = p.height * 0.5;

      let a = new THREE.Vector2(-vh, 0);
      let b = new THREE.Vector2(0, z);
      let c = new THREE.Vector2(vh, 0);

      let ab = new THREE.Vector2().subVectors(a, b);
      let bc = new THREE.Vector2().subVectors(b, c);
      let ac = new THREE.Vector2().subVectors(a, c);

      let r = (ab.length() * bc.length() * ac.length()) / (2 * Math.abs(ab.cross(ac)));

      let center = new THREE.Vector2(0, z - r);
      let baseV = new THREE.Vector2().subVectors(a, center);
      let baseAngle = baseV.angle() - Math.PI * 0.5;
      let arc = baseAngle * 2;
      let uv = this.geometry.attributes.uv as THREE.Float32BufferAttribute;
      let pos = this.geometry.attributes.position as THREE.Float32BufferAttribute;
      let mainV = new THREE.Vector2();
      for (let i = 0; i < uv.count; i++) {
        let uvRatio = 1 - uv.getY(i);
        let y = pos.getX(i);
        mainV.copy(c).rotateAround(center, arc * uvRatio);
        pos.setXYZ(i, y, mainV.x, -mainV.y);
      }

      pos.needsUpdate = true;
    }
  }

  private getLerpOutVector = () =>
    this.lerpOutVector.set(
      this.originalPosition.x,
      this.originalPosition.y + 5,
      this.originalPosition.z - 2
    );

  public switchEvent() {
    if (this.name === "gui") return; // ReCentre AR doesn't transition, it's always on

    document.addEventListener("rnib:switch", (e: CustomEventInit) => {
      const meshes = e.detail.meshes;
      let meshIsActive = false;

      meshes.forEach((mesh: string) => {
        if (mesh === this.name) {
          meshIsActive = true;
        }
      });

      if (meshIsActive && !this.visible) {
        this.updateTextureFromCanvas().then(message => {
          if (message === "updated") {
            this.material.opacity = 0;
            this.lerpVector = this.originalPosition;
            this.visible = true;
            this.getUICoordinates();
            setTimeout(() => {
              this.material.opacity = 1;
              this.lerpVector = undefined;
            }, params.fadeOutTime);
          }
        });
      } else if (this.visible && !meshIsActive) {
        this.lerpVector = this.getLerpOutVector();
        setTimeout(() => {
          this.visible = false;
          this.lerpVector = undefined;
        }, params.fadeOutTime);
      }
    });
  }

  public init() {
    this.updateTextureFromCanvas();

    document.addEventListener("rnib:click", e => {
      const target = e.target as HTMLButtonElement;
      const element = target.closest(".active");

      if (this.visible && element === this.HTMLElement) {
        this.updateTextureFromCanvas();
        this.getUICoordinates();
      }
    });

    document.addEventListener("rnib:update-ui", () => {
      this.updateTextureFromCanvas();
      this.getUICoordinates();
    });

    this.switchEvent();

    Array.from(this.HTMLElement.querySelectorAll<HTMLButtonElement>("button")).forEach(
      this.setupButtonsFromIndividualElement
    );
  }

  private setupButtonsFromIndividualElement = (currentButton: HTMLButtonElement) => {
    this.getUICoordinates();

    currentButton.addEventListener("focusin", () => {
      //console.log("[FOCUS TRIGGERED]", "isControllerEnabled: ", this.isControllerEnabled);
      clearTimeout(this.updateCanvasTimeout);

      currentButton.dispatchEvent(focusOnEvent);

      if (
        (currentButton.classList.contains("button") &&
          !currentButton.classList.contains("button-recenter")) ||
        currentButton.classList.contains("navigation--arrow")
      ) {
        //currentButton.style.backgroundColor = "white";
        //currentButton.style.color = "black";
        currentButton.style.filter = "invert(1)";
      } else {
        currentButton.style.backgroundColor = "black";

        if (currentButton.style.color == "") currentButton.style.color = "#FFFFFF";
      }

      this.updateCanvasTimeout = setTimeout(() => {
        this.updateTextureFromCanvas();
      }, 20);

      if (!this.isControllerEnabled) {
        this.loadingTimeout = setTimeout(() => {
          currentButton.click();
          currentButton.dispatchEvent(clickEvent);
          currentButton.blur();
        }, params.focusTime + params.focusDelay);
      }
    });

    currentButton.addEventListener("focusout", () => {
      currentButton.blur();

      currentButton.removeAttribute("style");

      clearTimeout(this.loadingTimeout);

      this.updateTextureFromCanvas();
    });
  };

  public getUICoordinates = () => {
    this.buttonsElements = Array.from(this?.HTMLElement?.querySelectorAll("button"));
    this.currentButtonId = null;

    if (!this.buttonsElements) return;
    this.buttonCoordinates = [];
    const { x, y } = this.HTMLElement.getBoundingClientRect();
    this.buttonsElements.forEach((element, index) => {
      if (element.classList.contains("hidden") || element.classList.contains("unclickable")) return;
      element.tabIndex = 0;
      const rect = element.getBoundingClientRect();
      this.buttonCoordinates.push({
        index,
        x1: rect.left - x,
        x2: rect.right - x,
        y1: rect.top - y,
        y2: rect.bottom - y,
      });
    });
  };

  private setMaterialFromCanvas(canvas: HTMLCanvasElement) {
    const { width, height } = canvas;
    const { material } = this;

    function mapMatchesCanvas(map: any) {
      if (!map) return false;
      else return map.width === width && map.height === height;
    }

    if (!mapMatchesCanvas(material.map)) {
      // create new map to match new canvas size
      material.map = new THREE.CanvasTexture(canvas);

      // scale the geometry to match the new size
      this.scale.set(width / 100, height / 100, 1);
    } else {
      // re-use the old texture
      material.map!.image = canvas;
    }

    material.transparent = true;
    material.needsUpdate = true;
  }

  private resizeCanvasToElement() {
    let canvas;
    if (!this.canvasContext) {
      canvas = document.createElement("canvas");
      this.canvasContext = canvas.getContext("2d")!;
    } else {
      canvas = this.canvasContext.canvas;
    }
    const { clientWidth: width, clientHeight: height } = this.HTMLElement;
    canvas.width = width;
    canvas.height = height;
    return canvas;
  }

  public updateTextureFromCanvas = async () => {
    const t0 = performance.now();
    const canvas = this.resizeCanvasToElement();

    return await htmlToCanvas(this.HTMLElement, this.canvasContext).then(() => {
      logStats("htmlToCanvas", this.name);
      this.setMaterialFromCanvas(canvas);
      return "updated";
    });

    function logStats(method: string, name: string) {
      const elapsed = Math.round(performance.now() - t0);
      console.log("%s: %s  (%d ms)", method, name, elapsed);
    }
  };

  public checkUICoordinates(intersectionUv: THREE.Vector2) {
    const mouseX = intersectionUv.x * this.HTMLElement.clientWidth;
    const mouseY = this.HTMLElement.clientHeight - intersectionUv.y * this.HTMLElement.clientHeight;
    // reset values
    this.buttonSelected = false;
    for (let i = 0; i < this.buttonCoordinates.length; i++) {
      if (
        isWithinRange(mouseX, this.buttonCoordinates[i].x1, this.buttonCoordinates[i].x2) &&
        isWithinRange(mouseY, this.buttonCoordinates[i].y1, this.buttonCoordinates[i].y2)
      ) {
        this.activateButton(
          this.buttonsElements[this.buttonCoordinates[i].index],
          this.buttonCoordinates[i]
        );
        return;
      }
    }

    if (!this.buttonSelected && !this.backgroundUpdated) {
      (document.activeElement as HTMLElement).blur();
      document.body.dispatchEvent(focusOutEvent);
      this.backgroundUpdated = true;
      this.currentButtonId = null;
    }
  }

  /**
   *
   * @param direction Direction of controller input; 0 = top, 1 = right, 2 = bottom, 3 = left
   */

  public triggerSelectElementFromDirection = (direction: number) => {
    if (!this.currentButtonId) this.currentButtonId = this.buttonCoordinates[0].index;

    // let closest: Nullable<{ coordinates: coordinates; distance: number }> = null;
    let closest: any = null;

    const elems = this.getAllElementsFromDirection(direction);

    elems.forEach(coordinates => {
      const distance = this.elementDistanceFromCurrent(coordinates);

      console.log(distance, this.buttonsElements[coordinates.index]);
      if (!closest || closest.distance > distance) closest = { coordinates, distance };
    });

    if (closest === null) return;

    // console.log("[CLOSEST]", closest, this.buttonsElements[closest.coordinates.index]);

    this.activateButton(this.buttonsElements[closest.coordinates.index], closest.coordinates);
  };

  private elementDistanceFromCurrent = (coords: coordinates) => {
    if (typeof this.currentButtonId !== "number") throw Error("Current Button ID needs to exist");

    const currButtonCoords = this.buttonCoordinates.find(
      coords => coords.index === this.currentButtonId
    )!;

    const centreCurrElem = {
      x: currButtonCoords.x1 + (currButtonCoords.x2 - currButtonCoords.x1) / 2,
      y: currButtonCoords.y1 + (currButtonCoords.y2 - currButtonCoords.y1) / 2,
    };

    const centreElem = {
      x: coords.x1 + (coords.x2 - coords.x1) / 2,
      y: coords.y1 + (coords.y2 - coords.y1) / 2,
    };

    const a = Math.abs(centreCurrElem.x - centreElem.x);
    const b = Math.abs(centreCurrElem.y - centreElem.y);

    return Math.sqrt(a * a + b * b);
  };

  private getAllElementsFromDirection = (direction: number) => {
    if (typeof this.currentButtonId !== "number") throw Error("Current Button ID needs to exist");
    const currButtonCoord = this.buttonCoordinates.find(
      coords => coords.index === this.currentButtonId
    )!;
    if (!currButtonCoord) debugger;

    const fromDirection = [
      (coords: coordinates) => coords.y2 < currButtonCoord.y1,
      (coords: coordinates) => coords.x1 > currButtonCoord.x2,
      (coords: coordinates) => coords.y1 > currButtonCoord.y2,
      (coords: coordinates) => coords.x2 < currButtonCoord.x1,
    ];

    return this.buttonCoordinates.filter(coords =>
      coords.index === currButtonCoord.index ? false : fromDirection[direction](coords)
    );
  };

  public triggerClickSelect = () => {
    if (typeof this.currentButtonId !== "number") return;
    const elem = this.buttonsElements[this.currentButtonId];

    elem.click();
    elem.dispatchEvent(clickEvent);
    elem.blur();
  };

  private activateButton = (currentButton: HTMLButtonElement, buttonCoordinates: coordinates) => {
    // check if any button is selected in the array
    this.buttonSelected = true;
    this.backgroundUpdated = false;

    if (this.currentButtonId !== buttonCoordinates.index) {
      this.currentButtonId = buttonCoordinates.index;

      if (!currentButton.classList.contains("hidden") || !currentButton.getAttribute("disabled")) {
        currentButton.focus();
      }
    }
  };

  public update() {
    if (this.lerpVector) {
      this.position.lerp(this.lerpVector, params.lerpAlpha);
      if (this.lerpVector.y !== this.originalPosition.y) {
        if (this.material.opacity > 0) {
          this.material.opacity -= 0.02;
        }
      } else {
        if (this.material.opacity < 1.02) {
          this.material.opacity += 0.02;
        }
      }
    }
  }
}
