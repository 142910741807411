import * as THREE from "three";
import Stats from "stats.js";
import { PointerLockControls } from "three/examples/jsm/controls/PointerLockControls";
import DeviceOrientationControls from "./utils/DeviceOrientationControls";
import * as ZapparThree from "@zappar/zappar-threejs";
import WorldScene from "./WorldScene";
import EyewareAssets from "./EyewareAssets";
import EyewareEffects from "./EyewareEffects";
import { promptForPermissions } from "./utils/permissions";
import Renderer from "./Renderer";
import { getMobileOS } from "./utils";
import CaptionCSSRenderer from "./CaptionCSSRenderer";
import GamepadObject from "./utils/GamepadObject";
import { Nullable } from "../types";

const isOrientationControls = (
  controls: PointerLockControls | DeviceOrientationControls
): controls is DeviceOrientationControls => controls instanceof DeviceOrientationControls;

type OrientationValues = "landscape" | "portrait";

export default class Experience {
  private gamepadObject: Nullable<GamepadObject>;
  private orientation: OrientationValues;
  private readonly deviceType = getMobileOS();
  private renderer: Renderer;
  private captionCSSRenderer: CaptionCSSRenderer;
  private scene: WorldScene;
  private camera: THREE.PerspectiveCamera;
  private zapparCamera: ZapparThree.Camera;
  private zapparCameraPlaying = false;
  private controls: PointerLockControls | DeviceOrientationControls;
  private isDeviceOrientationControls: boolean;

  private eyeConditionType: string;
  private severityLevel: number;
  private eyewareEffects: EyewareEffects;

  public stats: Stats;
  private stereoMode = false;
  private firstTick = false;
  private useController = false;
  private hasTouchScreen = false;

  constructor(gamepadObject: Nullable<GamepadObject>, orientation: OrientationValues) {
    this.gamepadObject = gamepadObject;
    this.orientation = orientation;

    if ("maxTouchPoints" in navigator) {
      this.hasTouchScreen = navigator.maxTouchPoints > 0;
    }

    this.stats = new Stats();
    this.eyeConditionType = "none";
    this.severityLevel = 1;

    this.camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );

    this.camera.position.z = 0;

    this.renderer = new Renderer({
      orientation: this.orientation,
      stereoMode: this.stereoMode,
    });

    this.captionCSSRenderer = new CaptionCSSRenderer({
      stereoMode: this.stereoMode,
    });

    this.scene = new WorldScene(this.camera, this.orientation, this.gamepadObject);

    if (this.hasTouchScreen) {
      this.controls = new DeviceOrientationControls({
        camera: this.camera,
        isAndroid: this.deviceType === "Android",
      });
    } else {
      this.controls = new PointerLockControls(this.camera, this.renderer.domElement);

      this.scene.add(this.controls.getObject());
    }

    this.isDeviceOrientationControls = isOrientationControls(this.controls);

    // The Zappar library needs the WebGL context to process camera images
    // Use this function to set your context
    ZapparThree.glContextSet(this.renderer.getContext());

    // Create a camera and set the scene background to the camera's backgroundTexture
    this.zapparCamera = new ZapparThree.Camera();
    this.zapparCamera.backgroundTexture.encoding = THREE.sRGBEncoding;

    this.eyewareEffects = new EyewareEffects({
      assets: EyewareAssets,
    });

    if (this.orientation === "landscape") {
      document.querySelector(".ui")?.classList.add("headset-mode");
    }

    this.eventListeners();
  }

  public getPermissions() {
    if (isOrientationControls(this.controls)) this.controls.connect();
    promptForPermissions().then(() => {
      this.zapparCamera.start();
      this.zapparCameraPlaying = true;
      //Temporary fix to Android black screen bug
      setTimeout(() => {
        this.zapparCamera.start();
        setTimeout(() => {
          this.zapparCamera.start();
        }, 500);
      }, 500);
    });
  }

  private onFirstTick = () => {
    this.scene.rotatePivotToCamera();
    this.firstTick = true;
  };

  private get isPlayingPanoramicVideo() {
    return this.scene.panoramicVideo.playing;
  }

  private get isRenderingStereo() {
    // Renderer uses orientation as well as stereoMode, so we duplicate logic here
    return this.stereoMode && this.orientation === 'landscape';
  }

  private getZapparCameraTextureMatrix(out) {
    const { renderer, zapparCamera } = this;
    const { drawingBufferWidth: w, drawingBufferHeight: h } = renderer.getContext();
    const s = this.isRenderingStereo ? 1 : 0;
    const m = zapparCamera.pipeline.cameraFrameTextureMatrix(w >> s, h, false);

    out.identity();
    out.elements[0] = m[0];   // x scale
    out.elements[4] = m[5];   // y scale
    out.elements[6] = m[12];  // x translate
    out.elements[7] = m[13];  // y translate
    return out;
  }

  private setZapparCameraPlaying(playing) {
    if (this.zapparCameraPlaying === playing) {
      // already good
      return;
    }

    if (playing) {
        // restart the camera source
        console.log("zapparCamera: restarting");
        this.zapparCamera.start();
        this.zapparCameraPlaying = true;
    } else {
        // pause the camera source
        console.log("zapparCamera: pausing");
        //@ts-ignore
        this.zapparCamera.pause();
        this.zapparCameraPlaying = false;
      }
  }

  private updateZapparCamera() {
    if (this.isPlayingPanoramicVideo) {
      // playing 360 video: pause the zappar camera
      this.setZapparCameraPlaying(false);
    } else {
      // AR view: ensure camera started and update the frame
      this.setZapparCameraPlaying(true);
      this.zapparCamera.updateFrame(this.renderer);
    }
  }

  flipCameras = true;

  private getEyeCameras() {
    // ensure scene camera matrices are up to date
    const {camera} = this;
    camera.updateWorldMatrix(true, true);

    if (this.isRenderingStereo) {
      // update the stereo camera generator and return each eye
      const {stereo} = this.renderer.stereoEffect;
      if (this.flipCameras) {
        stereo.update(camera);
        return [ stereo.cameraR, stereo.cameraL ];
      } else {
        // is this the same??
        stereo.eyeSep = -stereo.eyeSep;
        stereo.update(camera);
        stereo.eyeSep = -stereo.eyeSep;
        return [ stereo.cameraL, stereo.cameraR ];
      }
    } else {
      // return the main camera only for mono
      return [camera];
    }
  }

  private getEyewareEffectsSourceTexture() {
    if (!this.isPlayingPanoramicVideo) {
      // source texture is the zappar camera feed
      const texture = this.zapparCamera.backgroundTexture;
      this.getZapparCameraTextureMatrix(texture.matrix);
      return texture;
    } else {
      // source texture is the 360 video
      const texture = this.scene.panoramicVideo.texture;
      texture.matrix.identity();
      return texture;
    }
  }

  private animate = () => {
    this.stats.begin();

    this.scene.update();
    this.isDeviceOrientationControls && (this.controls as DeviceOrientationControls).update();

    this.updateZapparCamera();
    this.camera.updateWorldMatrix(true, true);

    // render effects passes
    this.eyewareEffects.render({
      renderer: this.renderer,
      cameras: this.getEyeCameras(),
      mode: this.isRenderingStereo ? "stereo" : "mono",
      sourceTexture: this.getEyewareEffectsSourceTexture(),
      condition: this.eyeConditionType,
      severity: this.severityLevel,
    });

    this.firstTick || this.onFirstTick();

    this.renderer.mainRender(this.scene, this.camera,
      this.eyewareEffects.textureLeft, this.eyewareEffects.textureRight);
    this.captionCSSRenderer.mainRender(this.scene, this.camera, this.renderer.stereoEffect.stereo);

    this.stats.end();

    // Ask the browser to call this function again next frame
    requestAnimationFrame(this.animate);
  };

  public startScene(orientation: OrientationValues, isStereoMode: boolean, useController: boolean) {
    this.stereoMode = isStereoMode;
    this.orientation = orientation;
    this.useController = useController;

    this.renderer.setStereoMode(this.stereoMode);

    this.stats.showPanel(0);
    const thisParent = document.querySelector(".stats_modal_container");
    thisParent?.appendChild(this.stats.dom);

    if (this.orientation === "landscape") {
      this.scene.init(true, useController).then(() => {
        this.scene.showInitialMesh(this.useController);
        if (!this.isDeviceOrientationControls) {
          window.addEventListener("click", () => (this.controls as PointerLockControls).lock(), {
            once: false,
          });
        }

        document.body.scrollTo({
          behavior: "auto",
          top: 0,
          left: 0,
        });
      });
    } else {
      this.scene.init(false, useController).then(() => {
        this.scene.showInitialElement();
      });
    }

    // Ask the browser to call this function again next frame
    requestAnimationFrame(this.animate);
  }

  private eventListeners() {
    document.addEventListener("rnib:refocus", this.scene.rotatePivotToCamera);
    document.addEventListener("rnib:condition", (e: CustomEventInit) => {
      let condition = e.detail.condition;
      if (condition.startsWith("armd-")) {
        condition = "armd";
      }
      this.eyeConditionType = condition;
      this.severityLevel = e.detail.severity;
    });
    document.addEventListener('rnib:flipCameras', () => {
      this.flipCameras = !this.flipCameras;
      console.log('flipCameras: %o', this.flipCameras);
    });
  }
}
