import { switchToMeshEvent, switchToVideoEvent } from "../utils/Events";
import { setExternalLink } from "./ExternalLink";
import { once } from "../utils";

const inGameUI = document.querySelector(".ui");

const homepageElement = inGameUI?.querySelector("#homepage");
const main = inGameUI?.querySelector("#main");

const eyeConditionsContainer = main?.querySelector(".eye-conditions-container");
const taskModeGrid = main?.querySelector(".task-mode-grid");

const selectEyeConditionButton = homepageElement?.querySelector(".select-eye-condition");
const findOutMoreButton = homepageElement?.querySelector("#find-out-more-button");
const surveyButton = homepageElement?.querySelector(".survey");
const videoButton = homepageElement?.querySelector(".three-sixty-video");
const taskModeButton = homepageElement?.querySelector(".task-mode");
const donateButton = homepageElement?.querySelector("#donate-button");

const toolTip = main?.querySelector("#tooltip-main-button");
const homeButton = main?.querySelector("#main-ui-home-button");
const replayButton = main?.querySelector("#main-ui-replay-button");
// const scenarioButton = main?.querySelector("#main-ui-scenario-button");
const closeButton = main?.querySelector("#main-ui-close-button");
const soundButton = main?.querySelector("#task-mode__sound-button");
const mainUIEyeConditionButton = main?.querySelector("#main-ui-eye-condition-button");

const externalSite = inGameUI?.querySelector("#external-site");
const severityScaleContainer = main?.querySelector(".severity-scale-container");

const findOutMoreIcon = `<svg width="38px" height="34px" viewBox="0 0 38 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<title>Group 9</title>
<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Free-control-mode-headset-1-Copy-13" transform="translate(-515.000000, -303.000000)">
        <g id="Group-9" transform="translate(517.000000, 305.000000)">
            <path d="M20.8719268,2.791741 C26.4461025,7.22025399 27.3851672,15.3419555 22.969414,20.9322384 C18.5536608,26.5225213 10.4551825,27.4642995 4.88118443,23.0357865 C-0.692991306,18.6072735 -1.63205599,10.4853939 2.78369721,4.89528912 C7.19945042,-0.694993778 15.297751,-1.63677199 20.8719268,2.791741 Z" id="Stroke-1" stroke="#FEFEFE" stroke-width="3.14928" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M19.1647544,4.95298573 C23.5487141,8.43597976 24.2872484,14.8235767 20.8142898,19.2200261 C17.3415089,23.6166537 10.9723164,24.3573221 6.58835678,20.8745062 C2.20439714,17.3915121 1.46586282,11.0039152 4.93864375,6.60728766 C8.41160229,2.2106601 14.7807948,1.4699917 19.1647544,4.95298573" id="Fill-3" fill="#FEFEFE"></path>
            <line x1="31.1952619" y1="27.4674881" x2="22.9694317" y2="20.9322206" id="Stroke-5" stroke="#FEFEFE" stroke-width="3.14928" stroke-linecap="round" stroke-linejoin="round"></line>
            <path d="M35.7276744,29.0901183 L33.8074496,31.5210711 C33.3754834,32.067933 32.5831309,32.1602049 32.037667,31.7268123 L25.6151892,26.6242519 C25.0697253,26.1910375 24.977897,25.3963954 25.4098632,24.8495335 L27.3300879,22.4185807 C27.7622318,21.8715406 28.5545842,21.7794469 29.0998705,22.2126613 L35.5225259,27.3152218 C36.0678122,27.7486143 36.1596406,28.5432564 35.7276744,29.0901183" id="Fill-7" fill="#FEFEFE"></path>
        </g>
    </g>
</g>
</svg>`;

/* Functions */

/* Export */

export const Homepage = once((orientation: string) => {
  selectEyeConditionButton?.addEventListener("click", () => {
    main?.classList.remove("space-between");
    eyeConditionsContainer?.classList.remove("hidden");
    taskModeGrid?.classList.add("hidden");
    replayButton?.classList.add("hidden");
    homeButton?.classList.remove("hidden");
    closeButton?.classList.add("hidden");

    if (orientation === "portrait") {
      homepageElement?.classList.remove("active");
      main?.classList.add("active");
    } else {
      switchToMeshEvent(["main"]);
    }
  });

  if (findOutMoreButton) {
    if (orientation === "portrait") {
      findOutMoreButton.innerHTML = `Find out more`;
    } else {
      findOutMoreButton.innerHTML = `Find out more ${findOutMoreIcon}`;
    }
  }

  findOutMoreButton?.addEventListener("click", () => {
    setExternalLink("https://www.rnib.org.uk/about-us/");

    if (orientation === "portrait") {
      homepageElement?.classList.remove("active");
      externalSite?.classList.add("active");
    } else {
      switchToMeshEvent(["external-site"]);
    }
  });

  videoButton?.addEventListener("click", () => {
    toolTip?.classList.add("hidden");
    main?.classList.add("space-between");
    eyeConditionsContainer?.classList.add("hidden");
    mainUIEyeConditionButton?.classList.add("hidden");
    taskModeGrid?.classList.add("hidden");
    closeButton?.classList.add("hidden");
    homeButton?.classList.remove("hidden");

    replayButton?.classList.remove("hidden");

    switchToVideoEvent(true);

    if (orientation === "portrait") {
      homepageElement?.classList.remove("active");
      main?.classList.add("active");
    } else {
      switchToMeshEvent(["main"]);
    }
  });

  if (!surveyButton) return;

  surveyButton?.addEventListener("click", () => {
    setExternalLink("https://www.surveymonkey.com/r/?sm=gYvln%2Bx%2FddP82dwc%2BGnifg%3D%3D");

    if (orientation === "portrait") {
      homepageElement?.classList.remove("active");
      externalSite?.classList.add("active");
    } else {
      switchToMeshEvent(["external-site"]);
    }
  });

  taskModeButton?.addEventListener("click", () => {
    if (toolTip) {
      toolTip.innerHTML = "Choose your scenario";
    }
    main?.classList.add("space-between");
    severityScaleContainer?.classList.add("hidden");
    eyeConditionsContainer?.classList.add("hidden");
    closeButton?.classList.add("hidden");
    replayButton?.classList.add("hidden");
    taskModeGrid?.classList.remove("hidden");
    homeButton?.classList.remove("hidden");
    soundButton?.classList.add("hidden");
    if (orientation === "portrait") {
      homepageElement?.classList.remove("active");
      main?.classList.add("active");
    } else {
      switchToMeshEvent(["main"]);
    }
  });

  donateButton?.addEventListener("click", () => {
    setExternalLink("https://www.rnib.org.uk/get-involved/donate/");
    if (orientation === "portrait") {
      homepageElement?.classList.remove("active");
      externalSite?.classList.add("active");
    } else {
      switchToMeshEvent(["external-site"]);
    }
  });
});

export default Homepage;
