export let params = {
  bendDepth: 4,
  eyeSep: 0,
  headsetBreakpoint: 500,
  focusTime: 3000,
  focusDelay: 1500,
  fadeOutTime: 3000,
  lerpAlpha: 0.1,
};

export function changeFocusLength(voicoverActive: boolean) {
  params.focusTime = voicoverActive ? 6000 : 3000;
}