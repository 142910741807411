import Hls from "hls.js";
import * as THREE from "three";
import { setEyeConditionEvent, eyeSepEvent } from "../dom/utils/Events";

// skip the camera rotation at start
const videoStartTime = 3.0;

function parseTime(s: string) {
  const bits = s.split(":");
  if (bits.length === 2) {
    return 60 * parseFloat(bits[0]) + parseFloat(bits[1]);
  } else {
    return parseFloat(bits[0]);
  }
}

type ConditionType = {
  in: string;
  out: string;
  condition: string;
  severity: number;
};

class ConditionDecisionList {
  cds: {
    timeRange: [number, number];
    condition: string;
    severity: number;
  }[] = [];
  constructor(args: ConditionType[]) {
    args.forEach(arg => {
      this.cds.push({
        timeRange: [parseTime(arg.in), parseTime(arg.out)],
        condition: arg.condition,
        severity: arg.severity,
      });
    });
  }
  findIndex(time: number) {
    return this.cds.findIndex(cd => {
      const [t0, t1] = cd.timeRange;
      return t0 <= time && time < t1;
    });
  }
  fireEvent(index: number) {
    if (index < 0) {
      setEyeConditionEvent("none", 1);
    } else {
      const { condition, severity } = this.cds[index];
      setEyeConditionEvent(condition, severity);
    }
  }
}

const conditionDecisionList = new ConditionDecisionList([
  {
    in: "0:41.2",
    out: "0:55.2",
    condition: "armd",
    severity: 4,
  },

  {
    in: "2:35.1",
    out: "02:54",
    condition: "diabetic-retinopathy",
    severity: 4,
  },

  {
    in: "5:29.0",
    out: "5:53.0",
    condition: "armd",
    severity: 4,
  },

  {
    in: "8:01.1",
    out: "8:22.1",
    condition: "cataracts",
    severity: 4,
  },

  {
    in: "9:59.2",
    out: "10:23",
    condition: "armd",
    severity: 4,
  },
]);

export class PanoramicVideo {
  public videoElement: HTMLVideoElement;
  public texture: THREE.VideoTexture;
  private conditionDecisionIndex = -1;

  constructor(source: string, _perspectiveCamera: THREE.PerspectiveCamera) {
    this.videoElement = document.createElement("video");
    this.videoElement.setAttribute("playsinline", "playsinline");
    this.videoElement.style.visibility = "hidden";
    this.videoElement.playsInline = true;

    this.videoElement.setAttribute("type", "video/mp4");
    this.videoElement.setAttribute("crossorigin", "anonymous");
    this.videoElement.width = 1920;
    this.videoElement.height = 1080;

    // HLS
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(source);
      hls.attachMedia(this.videoElement);
    } else if (this.videoElement.canPlayType("application/vnd.apple.mpegurl")) {
      this.videoElement.src = source;
    } else {
      throw Error("No video stream supported");
    }

    this.texture = new THREE.VideoTexture(this.videoElement, THREE.EquirectangularReflectionMapping);
    this.texture.magFilter = THREE.LinearFilter;
    this.texture.minFilter = THREE.LinearFilter;
    this.texture.needsUpdate = true;

    this.eventListener();
  }

  play() {
    setEyeConditionEvent("none", 1);
    this.conditionDecisionIndex = -1;
    this.videoElement.currentTime = videoStartTime;
    this.videoElement.play();
  }

  close() {
    this.videoElement.pause();
  }

  get playing() {
    return !(this.videoElement.paused || this.videoElement.ended);
  }

  private eyeconditions() {
    const { currentTime } = this.videoElement;
    const index = conditionDecisionList.findIndex(currentTime);
    if (index !== this.conditionDecisionIndex) {
      console.log("360 video: conditionDecisionIndex=%d", index);
      this.conditionDecisionIndex = index;
      conditionDecisionList.fireEvent(index);
    }
  }

  public update() {
    this.eyeconditions();
  }

  eventListener() {
    console.log("eventlistener");
    document.addEventListener("rnib:360-video", (e: CustomEventInit) => {
      console.log("360 video event");
      if (e.detail.state === true) {
        this.play();
      } else {
        this.close();
      }
    });

    let wet = false;
    function toggleExampleCondition() {
        if (wet) {
          setEyeConditionEvent('none', 1);
          wet = false;
        } else {
          setEyeConditionEvent('glaucoma', 3);
          wet = true;
        }
    }

    const clamp = (x, a, b) => x < a ? a : x > b ? b : x;

    let eyeSep = 0;
    function adjustEyeSep(dir) {
      const step = 0.5;
      const limits = [0.0, 6.5];
      eyeSep = clamp(eyeSep + step * dir, ...limits);
      eyeSepEvent(eyeSep);
      console.log('eyeSep:', eyeSep.toFixed(1));
    }

    const createElement = (tag, ...props) => {
      const el = Object.assign(document.createElement(tag), ...props);
      if (typeof props.style === 'string')
        el.style.cssText = props.style;
      return el;
    };

    const box = createElement('div', {
      style: `position: absolute; top: 0; right: 0; margin: 5px; display: flex; flex-flow: row; gap: 10px; background: rgba(0 0 0 / 25%); border-radius: 5px; padding: 5px 10px;`,
    });
    const label = createElement('div', {
      style: `color: white; font: 20px system-ui;`,
      innerText: 'eyeSep = 0.0',
    });
    const slider = createElement('input', {
      type: 'range',
      min: -65,
      max: 65,
      oninput(e) {
        eyeSep = parseInt(e.target.value) * 0.1,
        eyeSepEvent(eyeSep);
        label.innerText = 'eyeSep = ' + eyeSep.toFixed(1);
      },
    });
    box.append(label, slider);
    document.body.append(box);

    document.addEventListener('keydown', e => {
      switch (e.code) {
        case 'Digit1':
          toggleExampleCondition();
          break;

        case 'BracketLeft':
          adjustEyeSep(-1);
          break;

        case 'BracketRight':
          adjustEyeSep(+1);
          break;

        case 'KeyF':
          document.dispatchEvent(new CustomEvent('rnib:flipCameras'));
          break;

        default:
          return;
      }

      e.stopPropagation();
      e.preventDefault();
    });
  }
}
