import Homepage from "./Homepage";
import Main from "./Main";
import ExternalLink from "./ExternalLink";
import Tutorial from "./Tutorial";

export const fakeDomContent = (orientation: string) => {
  Homepage(orientation);
  Main(orientation);
  Tutorial();
  ExternalLink(orientation);
};
