import { findDomElement, getScreenOrientation } from "../utils";
import { fakeDomContent } from "./fakeDomContent";
import VRControllerImage from "../../../public/assets/images/VrControllerImage.jpg";
import HeadsetReferenceImage from "../../../public/assets/images/HeadsetReferenceOnly.jpg";
import CameraPermissionsImage from "../../../public/assets/images/CameraPopup.png";
import VRWarningIcon from "../../../public/assets/svg/VRwarningIcon.svg";

const introductionPage = findDomElement<"div">(".introduction");
const heading = findDomElement<"h1">(".introduction__body--heading", introductionPage);
const subheading = findDomElement<"h2">(".introduction__body--subheading", introductionPage);
const currentImage = findDomElement<"img">("img", introductionPage);
const inGameUI = findDomElement<"div">(".ui");

const continueButton = findDomElement<"button">("#continue-button", introductionPage);
const yesButton = findDomElement<"button">("#yes-controller-button", introductionPage);
const noButton = findDomElement<"button">("#no-controller-button", introductionPage);
const skipHeadsetButton = findDomElement<"button">("#skip-headset-button", introductionPage);

const splashScreen = document.querySelector("#splash_screen");

export const revealIntroScreen = () => {
  currentImage.src = CameraPermissionsImage;
  currentImage.alt = "Screenshot of pop-up for camera permissions";
  heading.innerHTML = "PLEASE ALLOW <br>CAMERA PERMISSIONS";
  subheading.innerHTML = `We'll need this to place the AR experience in your environment.`;
  return interfaceObj;
};

export const revealVrWarningScreen = () => {
  currentImage.src = VRControllerImage;
  currentImage.alt = "Person using handheld Virtual reality controllers";
  heading.innerHTML = "No Controller detected";
  subheading.innerHTML = `Pair your controller if you have one.<br><br> Press any button
        to detect your controller or continue using Gaze Control.`;
  return interfaceObj;
};

export const revealControllerDetectedScreen = () => {
  heading.innerHTML = "Controller detected";
  subheading.innerHTML = `Would you like to use it in this experience?`;
  currentImage.src = VRControllerImage;
  currentImage.alt = "Person using handheld Virtual reality controllers";
  continueButton.classList.add("hidden");
  yesButton.classList.remove("hidden");
  noButton.classList.remove("hidden");
  return interfaceObj;
};

export const revealVrWarning = () => {
  heading.innerHTML = "";
  subheading.innerHTML = `<br/><br/>Remember to be alert at all times.<br>
        Stay aware of your surroundings.`;
  currentImage.style.transform = "none";
  currentImage.style.marginRight = "1rem";
  currentImage.src = VRWarningIcon;
  currentImage.alt =
    "Illustrated warning of a virtual reality user tripping over household objects";
  yesButton.classList.add("hidden");
  noButton.classList.add("hidden");
  continueButton.classList.remove("hidden");

  return interfaceObj;
};

export const revealPlaceYourHeadset = () => {
  heading.innerHTML = "";
  subheading.innerHTML = `<br/><br/>Please place your device into headset for the 
          full immersive experience.`;
  currentImage.style.transform = "none";
  currentImage.style.marginLeft = "2rem";
  currentImage.src = HeadsetReferenceImage;
  currentImage.alt = "Person putting a smartphone into the RNIB Headset, on a tabletop";
  yesButton.classList.add("hidden");
  noButton.classList.add("hidden");
  skipHeadsetButton?.classList.remove("hidden");
  continueButton.classList.remove("hidden");

  return interfaceObj;
};

export const revealExperienceView = () => {
  introductionPage?.classList.add("hidden");
  document.body.removeAttribute("style");
  inGameUI?.classList.remove("hidden");
  fakeDomContent(getScreenOrientation());

  return interfaceObj;
};

const interfaceObj = {
  addContinueButtonCallback: (cb: (this: HTMLButtonElement, ev: MouseEvent) => void) => {
    continueButton.addEventListener("click", cb);

    return interfaceObj;
  },
  addYesButtonCallback: (cb: (this: HTMLButtonElement, ev: MouseEvent) => void) => {
    yesButton.addEventListener("click", cb);

    return interfaceObj;
  },
  addNoButtonCallback: (cb: (this: HTMLButtonElement, ev: MouseEvent) => void) => {
    noButton.addEventListener("click", cb);

    return interfaceObj;
  },
  addSkipHeadsetButtonCallback: (cb: (this: HTMLButtonElement, ev: MouseEvent) => void) => {
    skipHeadsetButton.addEventListener("click", cb);

    return interfaceObj;
  },
};

export function setupIntroductionPage() {
  inGameUI?.classList.add("hidden");
  document.body.style.padding = "var(--sat) var(--sar) var(--sab) var(--sal)";

  fakeDomContent(getScreenOrientation());
  setupSplashPageTimeout();
  return interfaceObj;
}

const setupSplashPageTimeout = () => {
  setTimeout(() => {
    if (!splashScreen) return;
    splashScreen?.classList.add("hidden");
    splashScreen?.setAttribute("aria-hidden", "true");
  }, 500);
};
