import { MathUtils, Camera, Vector3, Euler, Quaternion } from "three";

type DeviceOrientationControlsParams = {
  camera: Camera;
  isAndroid: boolean;
};

type PossibleDeviceOrientationEvents = "deviceorientationabsolute" | "deviceorientation";

class DeviceOrientationControls {
  private camera: Camera;
  private deviceOrientation!: DeviceOrientationEvent;

  private enabled = true;
  private isAndroid: boolean;
  private hasPlaced = false;
  private screenOrientation = 0;
  private orientationChangeEventName: PossibleDeviceOrientationEvents =
    "ondeviceorientationabsolute" in window ? "deviceorientationabsolute" : "deviceorientation";

  private defaultData = {
    position: new Vector3(),
    quaternion: new Quaternion(),
    rotation: new Euler(),
  };

  constructor({ camera, isAndroid }: DeviceOrientationControlsParams) {
    this.camera = camera;
    this.camera.rotation.reorder("YXZ");
    this.defaultData.position.copy(camera.position);
    this.defaultData.quaternion.copy(camera.quaternion);
    this.defaultData.rotation.copy(camera.rotation);
    this.isAndroid = isAndroid;

    this.bind();
  }

  private bind() {
    this.updateHasPlaced = this.updateHasPlaced.bind(this);

    this.onScreenOrientationChangeEvent = this.onScreenOrientationChangeEvent.bind(this);

    this.onDeviceOrientationChangeEvent = this.onDeviceOrientationChangeEvent.bind(this);
  }

  private updateHasPlaced(value: boolean) {
    this.hasPlaced = value;
  }

  public connect = () => {
    this.onScreenOrientationChangeEvent(); // run once on load

    window.addEventListener("orientationchange", this.onScreenOrientationChangeEvent, true);

    // @ts-ignore
    window.addEventListener(
      this.orientationChangeEventName,
      this.onDeviceOrientationChangeEvent,
      true
    );

    this.enabled = true;
  };

  /**
   * GYRO FUNCTIONS
   */
  private onDeviceOrientationChangeEvent = (event: DeviceOrientationEvent) => {
    this.deviceOrientation = event;
  };

  private onScreenOrientationChangeEvent = () => {
    this.screenOrientation = Number(window.orientation) || 0;
  };

  private cameraReset() {
    if (this.camera.quaternion !== this.defaultData.quaternion) {
      this.camera.position.copy(this.defaultData.position);
      this.camera.quaternion.copy(this.defaultData.quaternion);
      this.camera.rotation.copy(this.defaultData.rotation);
    }
  }

  private setObjectQuaternion = (() => {
    const zee = new Vector3(0, 0, 1);
    const euler = new Euler();
    const q0 = new Quaternion();
    const q1 = new Quaternion(-Math.sqrt(0.5), 0, 0, Math.sqrt(0.5)); // - PI/2 around the x-axis

    return (quaternion: Quaternion, alpha: number, beta: number, gamma: number, orient: number) => {
      euler.set(beta, alpha, -gamma, "YXZ"); // 'ZXY' for the device, but 'YXZ' for us
      quaternion.setFromEuler(euler); // orient the device
      quaternion.multiply(q1); // camera looks out the back of the device, not the top
      quaternion.multiply(q0.setFromAxisAngle(zee, -orient)); // adjust for screen orientation
    };
  })();

  private updateSensorValues = () => {
    if (this.deviceOrientation) {
      //We'er doing a check here for iOS or android
      let alpha = this.deviceOrientation.alpha
        ? MathUtils.degToRad(this.deviceOrientation.alpha)
        : 0; // Z; Alpha offset needs to be subtracted

      alpha = this.isAndroid ? alpha + MathUtils.degToRad(90) : alpha;

      const beta = this.deviceOrientation?.beta
        ? MathUtils.degToRad(this.deviceOrientation.beta)
        : 0; // X'
      const gamma = this.deviceOrientation?.gamma
        ? MathUtils.degToRad(this.deviceOrientation.gamma)
        : 0; // Y''
      const orient = this.screenOrientation ? MathUtils.degToRad(this.screenOrientation) : 0; // O

      if (this.hasPlaced) {
        this.cameraReset();
      } else {
        this.setObjectQuaternion(this.camera.quaternion, alpha, beta, gamma, orient);
      }
    }
  };

  /**
   * FRAME LOOP
   */
  update = () => {
    if (this.enabled === false) return;

    this.updateSensorValues();
  };

  /**
   * DISPOSE
   */
  dispose = () => {
    this.disconnect();
  };

  public disconnect = () => {
    console.log("disconnect GYRO");
    window.removeEventListener("orientationchange", this.onScreenOrientationChangeEvent, false);

    //@ts-ignore
    window.removeEventListener(
      this.orientationChangeEventName,
      this.onDeviceOrientationChangeEvent,
      false
    );

    this.enabled = false;
  };
}

export default DeviceOrientationControls;
