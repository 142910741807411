import { permissionGranted } from "@zappar/zappar-threejs";
import { Permission, permissionRequest } from "@zappar/zappar";

const hasPermissionAccess = async (permissionType: Permission) => {
  return permissionGranted(permissionType);
};

const promptForPermissionAccess = (permissionType: Permission) => {
  return permissionRequest(permissionType);
};

export const promptForPermissions = async () => {
  try {
    if (!(await hasPermissionAccess(Permission.MOTION)))
      await promptForPermissionAccess(Permission.MOTION);

    await promptForPermissionAccess(Permission.CAMERA);
  } catch (error) {
    throw error;
  }
};
