import { CSS2DRenderer } from "three/examples/jsm/renderers/CSS2DRenderer.js";
import { PerspectiveCamera, Scene, StereoCamera } from "three";

export default class CaptionCSSRenderer extends CSS2DRenderer {
  private isStereoMode: boolean;
  private stereoRenderer: CSS2DRenderer;

  constructor({ stereoMode }: { stereoMode: boolean }) {
    super();
    this.stereoRenderer = new CSS2DRenderer();
    this.isStereoMode = stereoMode;

    if (!this.isStereoMode) {
      this.setSize(window.innerWidth / 2, window.innerHeight);
      this.domElement.style.position = "absolute";
      this.domElement.style.top = "0px";
      this.domElement.style.left = "0";
      this.domElement.style.pointerEvents = "none";
      document.body.appendChild(this.domElement);

      this.stereoRenderer.setSize(window.innerWidth / 2, window.innerHeight);
      this.stereoRenderer.domElement.style.position = "absolute";
      this.stereoRenderer.domElement.style.top = "0px";
      this.stereoRenderer.domElement.style.left = "50%";
      this.stereoRenderer.domElement.style.bottom = "0";
      this.stereoRenderer.domElement.style.pointerEvents = "none";
      document.body.appendChild(this.stereoRenderer.domElement);
    } else {
      this.setSize(window.innerWidth, window.innerHeight);
      this.domElement.style.position = "absolute";
      this.domElement.style.top = "0px";
      this.domElement.style.left = "0";
      this.domElement.style.pointerEvents = "none";
      document.body.appendChild(this.domElement);
      console.log("stereo");
    }

    this.listeners();
  }

  private listeners() {
    window.addEventListener("resize", () => {
      this.stereoRenderer.setSize(window.innerWidth / 2, window.innerHeight);
      this.setSize(window.innerWidth / 2, window.innerHeight);
    });
  }

  private renderLandscape = (
    scene: Scene,
    stereo: StereoCamera
    // textureRight?: Texture
  ) => {
    this.render(scene, stereo.cameraL);
    if (this.domElement.lastElementChild) {
      this.stereoRenderer.domElement.firstElementChild?.remove();
      const clone = this.domElement.lastElementChild.cloneNode(true);
      this.stereoRenderer.domElement.appendChild(clone);
    }
    //this.stereoRenderer.render(scene, stereo.cameraR);
  };

  private renderPortrait = (scene: Scene, camera: PerspectiveCamera) => {
    // TODO: create stereo view for
    this.render(scene, camera);
  };

  mainRender = (
    scene: Scene,
    camera: PerspectiveCamera,
    stereo: StereoCamera
  ) => {
    if (!this.isStereoMode) this.renderLandscape(scene, stereo);
    else this.renderPortrait(scene, camera);
  };
}
