{
  "task-mode": {
    "scenes": [
      {
        "name": "Explore and experience different parts of the country",
        "tagName": "planning",
        "script": [
          {
            "type": "text",
            "copy": "Scenario 1: Explore and experience different parts of the country",
            "screenTime": 5500
          },
          {
            "type": "text",
            "copy": "This task requires that you have access to a computer,</br> tablet or additional smartphone.",
            "screenTime": 6500
          },
          {
            "type": "text",
            "copy": "Planning is an important part of any journey.",
            "screenTime": 3500
          },
          {
            "type": "text",
            "copy": "But if you are blind or partially sighted, this can</br>often be complicated before you have even left the door.",
            "screenTime": 6000
          },
          {
            "type": "text",
            "copy": "Let’s try it out.",
            "screenTime": 2000
          },
          {
            "type": "text",
            "copy": "Do you have your computer, tablet or additional smartphone ready?",
            "screenTime": 4000
          },
          {
            "type": "text",
            "copy": "Now open a journey app such as google or apple maps",
            "screenTime": 4000
          },
          {
            "type": "input",
            "copy": "Let us know when you are ready to continue",
            "screenTime": 2400
          },
          {
            "type": "text",
            "copy": "Looking at the screen, it is simple to begin planning a journey, right?",
            "screenTime": 4000
          },
          {
            "type": "random",
            "copy": "Now try and plan a journey by putting in a place or postcode",
            "screenTime": 4500
          },
          {
            "type": "input2",
            "copy": "Understanding the directions and information is difficult, isn’t it?",
            "screenTime": 4000
          },
          {
            "type": "text",
            "copy": "Did you know? 98% of the top 1 million websites’ homepages</br>have accessibility errors preventing tasks to be complete",
            "screenTime": 9000
          },
          {
            "type": "text",
            "copy": "Many blind and partially sighted people are not able to make the journeys</br>they want to, limiting the ability to work and participate in society.",
            "screenTime": 9000
          },
          {
            "type": "text",
            "copy": "40% need support during every trip, and 80% need</br>support on a journey that’s unfamiliar to them.",
            "screenTime": 7500
          },
          {
            "type": "text",
            "copy": "Over half (61%) of people with sight loss find</br>it difficult to navigate public transport facilities",
            "screenTime": 7000
          },
          {
            "type": "text",
            "copy": "25% of blind and partially sighted people feel</br>they are not getting out independently enough",
            "screenTime": 8000
          },
          {
            "type": "end",
            "copy": "Would you like to know more about this eye condition?",
            "screenTime": 3000
          }
        ]
      },
      {
        "name": "Happily go shopping for the things I want",
        "tagName": "shopping",
        "script": [
          {
            "type": "text",
            "copy": "Scenario 2: Happily go shopping for the things I want",
            "screenTime": 5000
          },
          {
            "type": "text",
            "copy": "This task requires that you have access to packaged products</br>which have ingredients or instructions written on them.",
            "screenTime": 7500
          },
          {
            "type": "text",
            "copy": "Shopping should be an easy and enjoyable experience",
            "screenTime": 3500
          },
          {
            "type": "text",
            "copy": "But it can be tricky if you are blind or partially sighted.",
            "screenTime": 4000
          },
          {
            "type": "text",
            "copy": "Have you got your packaged product?",
            "screenTime": 3000
          },
          {
            "type": "input",
            "copy": "Let us know when you are ready to continue",
            "screenTime": 2500
          },
          {
            "type": "random",
            "copy": "Looking at the product through the phone's screen,</br>you can easily tell what it is you're holding right?",
            "screenTime": 5500
          },
          {
            "type": "input2",
            "copy": "Reading the information is difficult, isn’t it?",
            "screenTime": 4000
          },
          {
            "type": "text",
            "copy": "Did you know? 9 out of 10, that's (90%) blind and partially sighted</br>people find it difficult or impossible to read packing information",
            "screenTime": 10000
          },
          {
            "type": "text",
            "copy": "People living with sight loss for less than 5 years</br>are less likely to be able to go out shopping alone",
            "screenTime": 6000
          },
          {
            "type": "text",
            "copy": "People living with sight loss since birth or childhood are more likely</br>to be able to go shopping by themselves (Source VI lives report 2022)",
            "screenTime": 11900
          },
          {
            "type": "end",
            "copy": "Would you like to know more about this eye condition?",
            "screenTime": 3000
          }
        ]
      },
      {
        "name": "Keep on top of everything I want done around</br>the home, in the way that I want it.",
        "tagName": "home",
        "script": [
          {
            "type": "text",
            "copy": "Scenario 3: Keep on top of everything I want</br>done around the home, in the way that I want it",
            "screenTime": 9500
          },
          {
            "type": "text",
            "copy": "This task requires that you have access to a</br>letter or a nearby appliance such as a washing machine.",
            "screenTime": 6500
          },
          {
            "type": "text",
            "copy": "Being independent and managing things</br>at home we should be able to do",
            "screenTime": 4000
          },
          {
            "type": "text",
            "copy": "But if you are blind or partially sighted</br>this can be harder than you think.",
            "screenTime": 4500
          },
          {
            "type": "text",
            "copy": "Have you got a letter to hand or</br>are you near an appliance?",
            "screenTime": 3500
          },
          {
            "type": "input",
            "copy": "Let us know when you are ready to continue",
            "screenTime": 2500
          },
          {
            "type": "text",
            "copy": "Looking at the letter or text on the appliance menu</br>or appliance settings through your phone’s screen,",
            "screenTime": 5500
          },
          {
            "type": "text",
            "copy": "you can easily tell what it is you're</br>holding/access this information, right?",
            "screenTime": 5500
          },
          {
            "type": "random",
            "copy": "Try reading the information now",
            "screenTime": 3000
          },
          {
            "type": "input2",
            "copy": "Accessing information within a letter or changing settings</br>on an appliance becomes more difficult, doesn’t it?",
            "screenTime": 7000
          },
          {
            "type": "text",
            "copy": "Did you know? Around half of blind and partially sighted</br>people said that they needed some help around the home.",
            "screenTime": 6000
          },
          {
            "type": "text",
            "copy": "People with less sight and people with additional disabilities</br>were more likely to require support around the home.",
            "screenTime": 7000
          },
          {
            "type": "text",
            "copy": "The most common areas of support were around</br>cleaning and preparing meals. (Source: My Voice 2015)",
            "screenTime": 7000
          },
          {
            "type": "text",
            "copy": "Less than 20% of people living with sight loss</br>can manage everyday household tasks independently",
            "screenTime": 7500
          },
          {
            "type": "text",
            "copy": "Two thirds of people said managing household tasks positively</br>impacted their mental health (source VI lives report 2022)",
            "screenTime": 16000
          },
          {
            "type": "end",
            "copy": "Would you like to know more about this eye condition?",
            "screenTime": 3000
          }
        ]
      },
      {
        "name": "Navigate spaces",
        "tagName": "spaces",
        "script": [
          {
            "type": "text",
            "copy": "Scenario 4: Navigate spaces",
            "screenTime": 4500
          },
          {
            "type": "text",
            "copy": "This task requires that you are in a safe indoor location,</br>such as your home or an office environment.",
            "screenTime": 6500
          },
          {
            "type": "text",
            "copy": "Being able to safely and independently go from A to B",
            "screenTime": 5000
          },
          {
            "type": "text",
            "copy": "But if you are blind or partially sighted understanding of your surroundings</br>and how to get to where you want to go can be harder than you think.",
            "screenTime": 9000
          },
          {
            "type": "text",
            "copy": "Are you in a safe environment, think about a new location you</br>would like to move to, such as a toilet or entrance/exit",
            "screenTime": 7500
          },
          {
            "type": "input",
            "copy": "Let us know when you are ready to continue",
            "screenTime": 2500
          },
          {
            "type": "text",
            "copy": "Looking around you can see it’s safe to proceed, right?",
            "screenTime": 4000
          },
          {
            "type": "random",
            "copy": "Safely move towards your new location",
            "screenTime": 4000
          },
          {
            "type": "input2",
            "copy": "Moving through the environment can be</br>unsettling and sometimes a scary experience.",
            "screenTime": 4500
          },
          {
            "type": "text",
            "copy": "Did you know? Many blind and partially sighted people are not able to make the</br>journeys they want to, limiting the ability to work and participate in society.",
            "screenTime": 10500
          },
          {
            "type": "text",
            "copy": "2 in 5 that's 40% need support during every trip, and 4 in 5 that's</br>80% need support on a journey that’s unfamiliar to them",
            "screenTime": 10000
          },
          {
            "type": "text",
            "copy": "Over half of people with sight loss find it</br>difficult to navigate public transport facilities",
            "screenTime": 6000
          },
          {
            "type": "text",
            "copy": "98% of the top 1 million websites’</br>homepages have accessibility errors",
            "screenTime": 6500
          },
          {
            "type": "text",
            "copy": "28% of people with site loss said they use</br>technology for navigation and getting around outside",
            "screenTime": 7000
          },
          {
            "type": "text",
            "copy": "61% said they did not do this either because they</br>did not know how to or didn’t have the technology",
            "screenTime": 7000
          },
          {
            "type": "text",
            "copy": "7% said technology didn’t make a difference</br>to navigating (source VI lives report 2022)",
            "screenTime": 12000
          },
          {
            "type": "end",
            "copy": "Would you like to know more</br>about this eye condition?",
            "screenTime": 3000
          }
        ]
      }
    ]
  }
}