import { once } from "../utils";
import data from "../../json/eye-conditions.json";
import {
  clickEvent,
  closeTaskEvent,
  setEyeConditionEvent,
  setTaskEvent,
  switchToMeshEvent,
  switchToVideoEvent,
  updateUIEvent,
} from "../utils/Events";
import eyeConditionsData from "../../json/eye-conditions.json";
import { setExternalLink } from "./ExternalLink";
const eyeConditions = data["eye-conditions"];
const inGameUI = document.querySelector(".ui");

const homepageElement = inGameUI?.querySelector("#homepage");

const main = inGameUI?.querySelector("#main") as HTMLElement;
// const homeTopButtonContainer = main?.querySelector(".home__top-button-container");
const eyeConditionsContainer = main?.querySelector(".eye-conditions-container");

const severityScaleContainer = inGameUI?.querySelector("#severity-scale-container");
const severityScaleHeader = inGameUI?.querySelector<HTMLElement>(".severity-scale__header");
const severityScaleFooter = inGameUI?.querySelector(".severity-scale__footer");

const colorBlindnessGrid = inGameUI?.querySelector(".color-blindness-grid");
const severityScale = inGameUI?.querySelector(".severity-scale") as HTMLElement;
const severityScaleButtons = severityScale?.querySelectorAll("button");

const eyeConditionsGrid = main?.querySelector(".eye-conditions-grid") as HTMLElement;
const taskModeGrid = main?.querySelector(".task-mode-grid") as HTMLElement;
const taskModeButtons = taskModeGrid?.querySelectorAll("button");

const toolTip = main?.querySelector("#tooltip-main-button");
const toolTipExpandButton = main?.querySelector("#tooltip-expand-button");
const rnibInformation = main?.querySelector(".rnib-expanded-info");
const rnibInformationInner = main?.querySelector("span");

const homeButton = main?.querySelector("#main-ui-home-button");
const closeButton = main?.querySelector("#main-ui-close-button");
const replayButton = main?.querySelector("#main-ui-replay-button");
const collapseButton = main?.querySelector("#tooltip-collapse-button");

const soundButton = main?.querySelector("#task-mode__sound-button");

const eyeConditionButton = main?.querySelector("#main-ui-eye-condition-button");
const scenarioButton = main?.querySelector("#main-ui-scenario-button");

const donateButton = main?.querySelector("#main-ui-donate-button");
const findOutMoreButton = main?.querySelector("#main-ui-find-out-more-button");

const externalSite = inGameUI?.querySelector("#external-site");

const prevButton = main?.querySelector(".navigation--arrow.left");
const nextButton = main?.querySelector(".navigation--arrow.right");

const taskModeYesButton = main?.querySelector("#task-mode__yes-button");
const taskModeNoButton = main?.querySelector("#task-mode__no-button");
const taskModeContinueButton = main?.querySelector("#task-mode__continue-button");

let buttonPages: Array<HTMLElement> = [];
let currentPage = 0;
let togglingTip = false;

/* Functions */

const clearButtonContainer = () => {
  homeButton?.classList.add("hidden");
  closeButton?.classList.add("hidden");
  eyeConditionButton?.classList.add("hidden");
  scenarioButton?.classList.add("hidden");
  soundButton?.classList.add("hidden");
  replayButton?.classList.add("hidden");
  findOutMoreButton?.classList.add("hidden");
  taskModeYesButton?.classList.add("hidden");
  taskModeNoButton?.classList.add("hidden");
  taskModeContinueButton?.classList.add("hidden");
  donateButton?.classList.add("hidden");
};

function handleEyeConditionButtonClick(e: Event) {
  const eyeConditionType = (e?.target as HTMLElement).dataset?.type;

  if (!eyeConditionType || !toolTip) return;

  eyeConditionButton?.classList.remove("hidden");
  closeButton?.classList.add("hidden");

  if (eyeConditionType === "color-blindness") {
    setEyeConditionEvent("none", 1);
    eyeConditionsContainer?.classList.add("hidden");
    colorBlindnessGrid?.classList.remove("hidden");
    severityScale?.classList.add("hidden");
    severityScaleHeader?.classList.add("hidden");
    severityScaleFooter?.classList.add("hidden");
    severityScale.removeAttribute("data-eye-condition");
    severityScaleContainer?.classList.remove("hidden");
    toolTip?.classList.add("unclickable");
    toolTip?.classList.add("hidden");
    toolTip.innerHTML = "Color Blindness";
    switchToMeshEvent(["main", "severity-scale-container"]);
  } else if (eyeConditionType === "none" || eyeConditionType === "hemianopia") {
    setEyeConditionEvent(eyeConditionType, 3);
    eyeConditionsContainer?.classList.add("hidden");
    colorBlindnessGrid?.classList.add("hidden");
    severityScaleContainer?.classList.add("hidden");
    main?.classList.add("space-between");
    if (eyeConditionType === "none") {
      toolTip.innerHTML = "20/20 Vision";
      toolTip.classList.add("unclickable");
    } else {
      toolTip?.classList.remove("unclickable");
    }
  } else {
    severityScaleButtons?.forEach((severityScaleButton, index) => {
      if (index == 2) {
        severityScaleButton.classList.add("selected");
      } else {
        severityScaleButton.classList.remove("selected");
      }
    });
    setEyeConditionEvent(eyeConditionType, 3);
    eyeConditionsContainer?.classList.add("hidden");
    colorBlindnessGrid?.classList.add("hidden");
    severityScale?.classList.remove("hidden");
    severityScaleContainer?.classList.remove("hidden");
    severityScaleHeader?.classList.remove("hidden");
    severityScaleFooter?.classList.remove("hidden");
    severityScale.setAttribute("data-eye-condition", eyeConditionType);
    toolTip?.classList.add("hidden");
    toolTip?.classList.remove("unclickable");
    switchToMeshEvent(["main", "severity-scale-container"]);
  }
  toolTip?.classList.remove("expanded-info");
  rnibInformation?.classList.add("hidden");
  toolTip.classList.remove("hidden");
}

function generateEyeConditionButtons() {
  eyeConditions.forEach((eyeCondition, index) => {
    const buttonElement = document.createElement("button");
    buttonElement.setAttribute("data-type", eyeCondition.name);
    buttonElement.setAttribute("data-id", `${index}`);
    buttonElement.classList.add("cta");

    if (index >= 6) {
      //buttonElement.classList.add("hidden");
    }

    buttonElement.innerHTML = eyeCondition.tagName;
    buttonElement.addEventListener("click", handleEyeConditionButtonClick);

    eyeConditionsGrid.appendChild(buttonElement);
  });

  const colorBlindnessList = eyeConditions.find(object => object.tagName === "Colour Blindness");

  const colorBlindnessOptions = colorBlindnessList?.options;

  if (colorBlindnessOptions) {
    colorBlindnessOptions.forEach((option, index) => {
      const buttonElement = document.createElement("button");
      buttonElement.setAttribute("data-type", option.tagName);
      buttonElement.setAttribute("data-info", option.desc);
      buttonElement.setAttribute("data-id", `${index}`);
      buttonElement.classList.add("cta");

      buttonElement.innerHTML = option.tagName;

      buttonElement.addEventListener("click", () => {
        if (toolTip && rnibInformationInner) {
          resetTooltip();
          //SeverityScale?.classList.add("ar-mode");
          toolTip.classList.remove("unclickable");
          toolTip.innerHTML = option.name;
          console.log("option name " + option.name);
          rnibInformationInner.innerHTML = option.desc;
          toolTip.classList.remove("hidden");
        }

        main?.classList.add("space-between");
        severityScaleContainer?.classList.add("hidden");
        setEyeConditionEvent(option.tagName, 5);
      });
      colorBlindnessGrid?.appendChild(buttonElement);
    });
  }

  const pagesLength = Math.ceil(eyeConditions.length / 6);
  if (pagesLength < 2) return eyeConditionsGrid;

  // Pagination
  for (let j = 0; j < pagesLength; j++) {
    const page = document.createElement("div");
    page.className = "eye-conditions-grid-page";
    page.style.display = j ? "none" : "grid";
    for (let i = 0; i < 6; i++) {
      if (eyeConditionsGrid.children[0]) {
        page.appendChild(eyeConditionsGrid.children[0]);
      }
    }
    buttonPages.push(page);
  }

  buttonPages.forEach(page => {
    eyeConditionsGrid.appendChild(page);
  });

  return eyeConditionsGrid;
}

const resetTooltip = () => {
  if (!toolTip || !rnibInformation || !rnibInformationInner) return;
  // Reset Tooltip
  toolTip.classList.add("unclickable");
  toolTip.innerHTML = "Select arrows for more eye conditions";
  rnibInformation.classList.add("hidden");
  rnibInformationInner.innerHTML = "";

  main?.classList.remove("space-between");
};

/* Export */

export const Main = once((orientation: string) => {
  generateEyeConditionButtons();

  severityScaleButtons?.forEach(severityScaleButton => {
    severityScaleButton?.addEventListener("click", () => {
      const eyeConditionData = severityScale?.dataset.eyeCondition;
      const severityLevelData = Number(severityScaleButton.dataset.severity);

      if (!eyeConditionData || !severityLevelData) return;

      severityScaleContainer?.classList.add("hidden");
      main?.classList.add("space-between");
      setEyeConditionEvent(eyeConditionData, severityLevelData);
      //SeverityScale?.classList.add("ar-mode");
      severityScaleHeader?.focus();
      toolTip?.classList.remove("hidden");

      severityScaleButtons?.forEach((severityScaleButton, index) => {
        severityScaleButton.classList.remove("selected");
        if (index == severityLevelData - 1) {
          severityScaleButton.classList.add("selected");
        }
      });
    });
  });

  if (inGameUI && prevButton && nextButton) {
    if (orientation === "landscape") {
    }
    setTimeout(
      () =>
        document.querySelectorAll<HTMLElement>(".eye-conditions-grid > div").forEach(page => {
          if (orientation === "landscape") {
            eyeConditionsGrid.style.maxWidth = "775px";
            //page.style.width = '775px';
          } else {
            page.style.width = `${inGameUI?.clientWidth - 80}px`;
          }
        }),
      300
    );
  }

  window.addEventListener("resize", () => {
    if (inGameUI && prevButton && nextButton) {
      setTimeout(
        () =>
          buttonPages.forEach(page => {
            if (orientation === "landscape") {
              eyeConditionsGrid.style.maxWidth = "775px";
              // page.style.width = '775px';
            } else {
              page.style.width = `${inGameUI?.clientWidth - 80}px`;
            }
          }),
        300
      );
    }
  });

  taskModeButtons.forEach(taskModeButton => {
    console.log("task mode button");
    const scene = taskModeButton.dataset.scene;
    taskModeButton.addEventListener("click", () => {
      if (!scene) return;
      clearButtonContainer();
      taskModeGrid?.classList.add("hidden");
      homeButton?.classList.remove("hidden");
      scenarioButton?.classList.remove("hidden");
      soundButton?.classList.remove("hidden");
      main?.classList.add("space-between");
      if (toolTip) {
        toolTip.innerHTML = "please turn on your audio for voiceover";
      }

      setTimeout(() => {
        toolTip?.classList.add("hidden");
        updateUIEvent();
      }, 5000);
      setTaskEvent(scene, true);
    });
  });

  scenarioButton?.addEventListener("click", () => {
    clearButtonContainer();
    homeButton?.classList.remove("hidden");
    scenarioButton.classList.add("hidden");
    soundButton?.classList.add("hidden");
    taskModeGrid?.classList.remove("hidden");

    if (toolTip) {
      toolTip.classList.add("unclickable");
      toolTip.classList.remove("hidden");
      toolTip.innerHTML = "Choose your scenario";
      toolTip?.classList.remove("expanded-info");
    }
    rnibInformation?.classList.add("hidden");
    closeTaskEvent();
  });

  homeButton?.addEventListener("click", () => {
    //toolTip?.classList.remove("hidden");
    toolTip?.classList.add("unclickable");
    toolTip?.classList.remove("expanded-info");
    rnibInformation?.classList.add("hidden");
    severityScaleContainer?.classList.add("hidden");
    //eyeConditionsContainer?.classList.remove("hidden");
    clearButtonContainer();
    //homeButton?.classList.remove("hidden");
    //closeButton?.classList.remove("hidden");

    closeTaskEvent();
    resetTooltip();
    switchToVideoEvent(false);
    setEyeConditionEvent("none", 1);
    if (orientation === "portrait") {
      homepageElement?.classList.add("active");
      main?.classList.remove("active");
    } else {
      switchToMeshEvent(["homepage"]);
    }
  });

  closeButton?.addEventListener("click", () => {
    clearButtonContainer();
    setEyeConditionEvent("none", 1);
    homeButton?.classList.remove("hidden");
    eyeConditionButton?.classList.remove("hidden");

    toolTip?.classList.remove("hidden");
    eyeConditionsContainer?.classList.add("hidden");
    severityScaleContainer?.classList.add("hidden");
  });

  function adjustCurrentPage(delta: number) {
    const numPages = buttonPages.length;
    const nextPage = Math.max(0, Math.min(numPages - 1, currentPage + delta));

    if (nextPage === currentPage) return;

    console.log("adjustCurrentPage: delta=%d  current=%d  next=%d", delta, currentPage, nextPage);

    currentPage = nextPage;
    buttonPages.forEach((page, index) => {
      const visible = index === currentPage;
      console.log(" - page %d: %o", index, visible);
      page.style.display = visible ? "grid" : "none";
    });
  }

  prevButton?.addEventListener("click", () => adjustCurrentPage(-1));
  nextButton?.addEventListener("click", () => adjustCurrentPage(+1));

  eyeConditionButton?.addEventListener("click", () => {
    if (orientation === "landscape") {
      switchToMeshEvent(["main"]);
    }
    setEyeConditionEvent("none", 1);
    clearButtonContainer();
    homeButton?.classList.remove("hidden");

    eyeConditionsContainer?.classList.remove("hidden");
    severityScaleContainer?.classList.add("hidden");
    resetTooltip();
  });

  replayButton?.addEventListener("click", () => {
    switchToVideoEvent(true);
  });

  collapseButton?.addEventListener("click", () => {
    toolTip?.classList.remove("expanded-info");
    rnibInformation?.classList.add("hidden");
    if (!main.classList.contains("space-between")) {
      severityScaleContainer?.classList.remove("hidden");
    }
  });

  toolTip?.addEventListener("click", () => {
    toggleTooltip();
  });

  toolTipExpandButton?.addEventListener("click", () => {
    toggleTooltip();
  });

  const toggleTooltip = () => {
    if (togglingTip) return;
    togglingTip = true;
    setTimeout(() => {
      togglingTip = false;
    }, 1000);

    if (!rnibInformation || !toolTip) return;

    if (!toolTip.classList.contains("unclickable")) {
      if (toolTip.classList.contains("expanded-info")) {
        toolTip.classList.remove("expanded-info");
        rnibInformation.classList.add("hidden");
        // severityScaleContainer?.classList.remove("hidden");
      } else {
        toolTip.classList.add("expanded-info");
        rnibInformation.classList.remove("hidden");
        // severityScaleContainer?.classList.add("hidden");
      }
    }
  };

  taskModeContinueButton?.addEventListener("click", () => {
    taskModeContinueButton?.classList.add("hidden");
    homeButton?.classList.remove("hidden");
    scenarioButton?.classList.remove("hidden");
    soundButton?.classList.remove("hidden");
    main.classList.remove("end");
    main?.classList.add("space-between");
    toolTip?.classList.remove("expanded-info");
    rnibInformation?.classList.add("hidden");
    toolTip?.classList.add("hidden");
    setEyeConditionEvent("none", 1);
  });

  taskModeYesButton?.addEventListener("click", () => {
    const anchorButton = document.createElement("a");
    const currentLink = taskModeYesButton.getAttribute("data-href");
    if (currentLink) {
      anchorButton.href = currentLink;
      anchorButton.setAttribute("target", "_blank");
      anchorButton.click();
    }
  });

  taskModeNoButton?.addEventListener("click", () => {
    clearButtonContainer();
    toolTip?.classList.add("hidden");
    homeButton?.classList.remove("hidden");
    scenarioButton?.classList.remove("hidden");
    donateButton?.classList.remove("hidden");
    findOutMoreButton?.classList.remove("hidden");
    soundButton?.classList.add("hidden");
  });

  findOutMoreButton?.addEventListener("click", () => {
    setExternalLink("https://www.rnib.org.uk/about-us/");
    if (orientation === "portrait") {
      homepageElement?.classList.remove("active");
      externalSite?.classList.add("active");
    } else {
      switchToMeshEvent(["external-site"]);
    }
  });

  donateButton?.addEventListener("click", () => {
    setExternalLink("https://www.rnib.org.uk/get-involved/donate/");

    if (orientation === "portrait") {
      homepageElement?.classList.remove("active");
      externalSite?.classList.add("active");
    } else {
      switchToMeshEvent(["external-site"]);
    }
  });

  document.addEventListener("rnib:condition", (e: CustomEventInit) => {
    const currenteyecondition = e.detail.condition;
    console.log("console " + JSON.stringify(e.detail));
    if (!currenteyecondition || currenteyecondition === "none") return;

    const givenEyeCondition = eyeConditionsData["eye-conditions"].find(
      eyeconditiondata => eyeconditiondata.name === currenteyecondition
    );

    if (rnibInformationInner && toolTip && givenEyeCondition) {
      toolTip.innerHTML = givenEyeCondition.tagName;
      console.log("givenEyeCondition name " + givenEyeCondition.tagName);
      rnibInformationInner.innerHTML = givenEyeCondition.desc;
      toolTip.dispatchEvent(clickEvent);
    }
  });

  document.addEventListener("rnib:end-task", () => {
    if (!toolTip) return;
    console.log("end task");
    homeButton?.classList.add("hidden");
    scenarioButton?.classList.add("hidden");
    soundButton?.classList.add("hidden");
    taskModeYesButton?.classList.remove("hidden");
    taskModeNoButton?.classList.remove("hidden");

    taskModeNoButton?.dispatchEvent(clickEvent);
  });

  document.addEventListener("rnib:reset-task", () => {
    if (!toolTip) return;
    clearButtonContainer();
    taskModeGrid?.classList.add("hidden");
    homeButton?.classList.remove("hidden");
    scenarioButton?.classList.remove("hidden");
    soundButton?.classList.remove("hidden");
    main?.classList.add("space-between");
    if (toolTip) {
      toolTip.innerHTML = "please turn on your audio for voiceover";
    }

    setTimeout(() => {
      toolTip?.classList.add("hidden");
      toolTip?.dispatchEvent(clickEvent);
    }, 5000);
  });

  document.addEventListener("rnib:continue-task", () => {
    if (!toolTip) return;
    clearButtonContainer();

    taskModeContinueButton?.classList.remove("hidden");
    // main?.classList.remove("space-between");

    taskModeContinueButton?.dispatchEvent(clickEvent);
  });

  document.addEventListener("rnib:continue-task2", () => {
    if (!toolTip) return;
    clearButtonContainer();
    taskModeContinueButton?.classList.remove("hidden");
    taskModeContinueButton?.dispatchEvent(clickEvent);
  });
});

export default Main;
