{
  "eye-conditions": [
    {
      "tagName": "20/20 Vision",
      "name": "none",
      "id": 0,
      "desc": "",
      "scaleType": "sliding",
      "hidden": false
    },
    {
      "tagName": "Age Related Macular degeneration - wet",
      "name": "armd-wet",
      "id": 1,
      "desc": "Age-related macular degeneration (AMD) affects a tiny part of the retina at the back of your eye, called the macula.  Although this condition doesn’t cause total loss of vision, it  can cause issues with central, detailed vision. AMD is the leading cause of sight loss for over 65s.",
      "scaleType": "sliding",
      "hidden": false,
      "url": "https://www.rnib.org.uk/your-eyes/eye-conditions-az/age-related-macular-degeneration-amd/"
    },
    {
      "tagName": "Age Related Macular degeneration - dry",
      "name": "armd-dry",
      "id": 2,
      "desc": "Age-related macular degeneration (AMD) affects a tiny part of the retina at the back of your eye, called the macula.  Although this condition doesn’t cause total loss of vision, it  can cause issues with central, detailed vision.",
      "scaleType": "sliding",
      "hidden": false,
      "url": "https://www.rnib.org.uk/your-eyes/eye-conditions-az/age-related-macular-degeneration-amd/"
    },
    {
      "tagName": "Retinitis pigmentosa",
      "name": "retinitis-pigmentosa",
      "id": 3,
      "desc": "Retinitis Pigmentosa (RP) denotes a group of progressive eye diseases that affect the retina.Over time, people with RP will gradually lose their peripheral vision causing a tunnel vision effect.  As time progresses RP will also affect central vision.",
      "scaleType": "sliding",
      "hidden": false,
      "url": "https://www.rnib.org.uk/your-eyes/eye-conditions-az/retinitis-pigmentosa/"
    },
    {
      "tagName": "Glaucoma",
      "name": "glaucoma",
      "id": 4,
      "desc": "Glaucoma is a common eye condition where the optic nerve, which connects the eye to the brain, becomes damaged usually due to high pressure within the eye. Initially affecting peripheral vision, it eventually progresses to central vision and can result in total loss of vision.",
      "scaleType": "sliding",
      "hidden": false,
      "url": "https://www.rnib.org.uk/your-eyes/eye-conditions-az/glaucoma/"
    },
    {
      "tagName": "Diabetic retinopathy",
      "name": "diabetic-retinopathy",
      "id": 5,
      "desc": "Diabetic retinopathy is an eye condition that can cause vision loss and blindness in people who have diabetes. It affects blood vessels in the retina and can affect peripheral vision as it progresses.  It is the second most common cause of sight loss for working age people.",
      "scaleType": "sliding",
      "hidden": false,
      "url": "https://www.rnib.org.uk/your-eyes/eye-conditions-az/retinitis-pigmentosa/"
    },
    {
      "tagName": "Hemianopia",
      "name": "hemianopia",
      "id": 6,
      "desc": "Hemianopia is most commonly caused by stroke.The most common form of this is a loss of half of one's visual field in both eyes, although strokes can cause differing loss of vision as well as difficulty in visual processing.",
      "scaleType": "sliding",
      "hidden": false,
      "url": "https://www.rnib.org.uk/your-eyes/eye-conditions-az/stroke-related-eye-conditions/"
    },
    {
      "tagName": "Cataracts",
      "name": "cataracts",
      "desc": "Cataracts occur when the lens inside your eye becomes cloudy. Naturally it is crystal clear but as you get older it becomes less clear and often becomes slightly brown in colour. This results in blurring, haze/ scattered light and dazzle in bright light.  It is very common as we get older but can be treated with surgery for the majority of cases.",
      "id": 7,
      "scaleType": "sliding",
      "hidden": false,
      "url": "https://www.rnib.org.uk/your-eyes/eye-conditions-az/cataracts/"
    },
    {
      "tagName": "Colour Blindness",
      "name": "color-blindness",
      "id": 8,
      "desc": "Colour vision deficiency - can be inhertied (most common) or acquired (caused by another condition/medication). Inherited CVD - most common is red/green deficiency, does not get better/worse with age. Most forms do not affect eye's ability to see detail. Usually colour blindness is inherited and not progressive. Some people acquire colour vision loss due to medication or eye conditions. People with macular conditions often have acquired loss of colour vision. ",
      "scaleType": "options",
      "hidden": false,
      "options": [
        {
          "name": "Deuteranopia",
          "tagName": "deuteranopia",
          "desc": "Deuteranopia is colour blindness resulting from insensitivity to green light, causing confusion of greens, reds, and yellows."
        },
        {
          "name": "Protanopia",
          "tagName": "protanopia",
          "desc": "Protanopia is colour blindness resulting from insensitivity to red light, causing confusion of greens, reds, and yellows. It is hereditary, and is the most common form of colour blindness"
        },
        {
          "name": "Tritanopia",
          "tagName": "tritanopia",
          "desc": "Tritanopia is a rare form of colour blindness resulting from insensitivity to blue light, causing confusion of greens and blues."
        },
        {
          "name": "Monochromacy (Achromatopsia)",
          "tagName": "achromatopsia",
          "desc": "Monochromacy is a lack of all colour detecting cells in the eye."
        }
      ]
    }
  ]
}
