import * as THREE from "three";
import { params } from "../../params";

export default class Reticle extends THREE.Object3D {
  public arcGeometry: THREE.TorusGeometry;
  public arcGhostGeometry: THREE.TorusGeometry;
  public sphereGeometry: THREE.SphereGeometry;

  private sphereMaterial: THREE.MeshBasicMaterial;
  private arcMaterial: THREE.MeshBasicMaterial;
  private arcGhostMaterial: THREE.MeshBasicMaterial;

  public arcMesh: THREE.Mesh;
  public arcGhostMesh: THREE.Mesh;
  public sphereMesh: THREE.Mesh;
  public sphere: THREE.Group;
  private focusing: boolean = false;
  private arc: number;
  private reticleTimerOffset: number;

  // private camera: THREE.Camera;
  private clock: THREE.Clock;
  private offsetTime: number;

  private outlineMesh: THREE.Mesh;
  private outlineMaterial: THREE.Material;

  constructor(clock: THREE.Clock) {
    super();
    this.sphereMaterial = new THREE.MeshBasicMaterial({
      color: "#0074FF",
    });
    this.arcMaterial = new THREE.MeshBasicMaterial({
      color: "#FFFFFF",
    });
    this.arcGhostMaterial = new THREE.MeshBasicMaterial({
      color: "#808080",
      opacity: 0.3,
    });
    this.arc = 2 * Math.PI;

    this.outlineMaterial = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      side: THREE.BackSide,
      visible: true,
    });
    this.arcGeometry = new THREE.TorusGeometry(0.4, 0.0001, 5, 20, this.arc);
    this.arcGhostGeometry = new THREE.TorusGeometry(0.4, 0.05, 3, 20);
    this.sphereGeometry = new THREE.SphereGeometry(0.05, 5, 5);

    this.arcMesh = new THREE.Mesh(this.arcGeometry, this.arcMaterial);
    this.arcGhostMesh = new THREE.Mesh(
      this.arcGhostGeometry,
      this.arcGhostMaterial
    );
    this.sphereMesh = new THREE.Mesh(this.sphereGeometry, this.sphereMaterial);
    this.arcMesh.rotation.set(0, Math.PI, Math.PI / 2);

    this.outlineMesh = new THREE.Mesh(
      this.sphereGeometry,
      this.outlineMaterial
    );
    this.outlineMesh.scale.multiplyScalar(1.3);
    // this.camera = camera;

    this.sphere = new THREE.Group();
    this.sphere.add(this.sphereMesh, this.outlineMesh);

    this.offsetTime = 0;
    this.clock = clock;
    this.reticleTimerOffset = 0;

    this.add(this.arcMesh);
    this.add(this.arcGhostMesh);
    this.add(this.sphere);
    // this.mesh.scale.set(0.1, 0.1, 0.1);

    this.init();
  }

  public defaultPointer() {
    this.focusing = false;
  }

  public loadingAnimation() {
    this.focusing = true;
  }

  public setPosition(intersectingPoint: THREE.Vector3) {
    let { x, y, z } = intersectingPoint;

    this.sphere.position.set(x, y, z + this.reticleTimerOffset);
    this.arcMesh.position.set(x, y, z + this.reticleTimerOffset);
    this.arcGhostMesh.position.set(x, y, z + this.reticleTimerOffset);
  }

  public rotateSprite() {
    // const vtr = new THREE.Vector3(this.mesh.position.x, this.mesh.position.y, this.mesh.position.z);
    // const quatCopy = vtr.quaternion.copy(this.camera.quaternion);
    const elapsedTime = this.clock.getElapsedTime();
    const time = elapsedTime - this.offsetTime;

    // this.mesh.applyQuaternion(this.camera.quaternion);
    // console.log(params.focusDelay, time, this.focusing);
    if (!this.arcMesh || !this.sphereMesh) return;

    if (
      this.focusing &&
      this.arc < 2 * Math.PI &&
      time > params.focusDelay / 1000
    ) {
      const adjustedTime = time - params.focusDelay / 1000;
      //this.arc += (Math.PI/90);
      this.arc = (2 * Math.PI * adjustedTime) / (params.focusTime / 1000);
      this.arcGeometry.dispose();
      this.arcGeometry = new THREE.TorusGeometry(0.4, 0.06, 3, 20, this.arc);
      this.arcMesh.geometry = this.arcGeometry;
      this.arcMesh.rotation.z = Math.PI * 0.5;
      this.reticleTimerOffset = 0.5;

      if (!this.arcMesh.visible && !this.arcGhostMesh.visible) {
        this.arcMesh.visible = true;
        this.arcGhostMesh.visible = true;
      }
    } else if (this.arc > 0.01) {
      if (this.arcMesh.visible && this.arcGhostMesh.visible) {
        this.arcMesh.visible = false;
        this.arcGhostMesh.visible = false;
      }
      this.arc = 0;
      this.reticleTimerOffset = 0;
    }

    if (this.arc > 2 * Math.PI) {
      this.focusing = false;
    }

    if (this.focusing === false) {
      this.offsetTime = this.clock.getElapsedTime();
    }
  }

  private init() {
    document.addEventListener("rnib:focuson", () => {
      this.loadingAnimation();
    });
    document.addEventListener("rnib:focusout", () => this.defaultPointer());
    document.addEventListener("rnib:click", () => this.defaultPointer());
  }
}
