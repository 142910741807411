import macula from '../../public/assets/textures/macula.png';
import cataracts from '../../public/assets/textures/cataracts.png';
import bluenoise from '../../public/assets/textures/bluenoise.png';

import camouflage1 from '../../public/assets/textures/camouflage-1.png';
import camouflage2 from '../../public/assets/textures/camouflage-2.png';
import camouflage3 from '../../public/assets/textures/camouflage-3.png';
import camouflage4 from '../../public/assets/textures/camouflage-4.png';
import camouflage5 from '../../public/assets/textures/camouflage-5.png';

import tunnel1 from '../../public/assets/textures/tunnel-1.png';
import tunnel2 from '../../public/assets/textures/tunnel-2.png';
import tunnel3 from '../../public/assets/textures/tunnel-3.png';
import tunnel4 from '../../public/assets/textures/tunnel-4.png';
import tunnel5 from '../../public/assets/textures/tunnel-5.png';

import glaucoma1 from '../../public/assets/textures/glaucoma-1.png';
import glaucoma2 from '../../public/assets/textures/glaucoma-2.png';
import glaucoma3 from '../../public/assets/textures/glaucoma-3.png';
import glaucoma4 from '../../public/assets/textures/glaucoma-4.png';
import glaucoma5 from '../../public/assets/textures/glaucoma-5.png';

export default {
    textures: {
        bluenoise,
        camouflage1,
        camouflage2,
        camouflage3,
        camouflage4,
        camouflage5,
        tunnel1,
        tunnel2,
        tunnel3,
        tunnel4,
        tunnel5,
        glaucoma1,
        glaucoma2,
        glaucoma3,
        glaucoma4,
        glaucoma5,
        macula,
        cataracts,
    },
};
